import React, { useState, useEffect } from 'react';
import { categories } from '../../Pages/ProjectPages/ProjectMilestonesPage';
import { milestoneService } from '../../../services/milestoneService';
import { getDocuments } from '../../../services/documentService';
import { toast } from 'react-toastify';
import { DocumentIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const CustomMilestoneForm = ({
  projectId,
  onMilestoneAdded,
  newMilestoneTitle,
  setNewMilestoneTitle,
  newMilestoneCategory,
  setNewMilestoneCategory,
  newMilestoneDeadline,
  setNewMilestoneDeadline,
}) => {
  const [documents, setDocuments] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    fetchAllDocuments();
  }, [projectId]);

  const fetchAllDocuments = async () => {
    try {
      const data = await getDocuments(projectId);
      setDocuments(data);
    } catch (error) {
      console.error('Error fetching all documents:', error);
      toast.error('Failed to fetch documents');
    }
  };

  const organizeDocuments = () => {
    return Object.entries(documents).reduce((acc, [category, subCategories]) => {
      acc[category] = Object.values(subCategories).flat();
      return acc;
    }, {});
  };

  const handleAddDocument = (e) => {
    const docId = e.target.value;
    if (docId && !selectedDocuments.includes(docId)) {
      setSelectedDocuments([...selectedDocuments, docId]);
    }
  };

  const handleRemoveDocument = (docId) => {
    setSelectedDocuments(selectedDocuments.filter((id) => id !== docId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const milestoneData = {
        title: newMilestoneTitle,
        category: newMilestoneCategory,
        deadline: newMilestoneDeadline,
        projectId: projectId,
        documentIds: selectedDocuments, // Send array of document IDs
      };

      await onMilestoneAdded(milestoneData);

      // Show success toast and reset form fields
      toast.success('Milestone added successfully!');
      setNewMilestoneTitle('');
      setNewMilestoneCategory('');
      setNewMilestoneDeadline('');
      setSelectedDocuments([]);
    } catch (error) {
      console.error('Error adding milestone:', error);
      toast.error('Failed to add milestone. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="space-y-4">
        <div>
          <label htmlFor="milestone-title" className="block text-sm font-medium text-gray-700">
            Title *
          </label>
          <input
            id="milestone-title"
            type="text"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            value={newMilestoneTitle}
            onChange={(e) => setNewMilestoneTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="milestone-category" className="block text-sm font-medium text-gray-700">
            Category *
          </label>
          <select
            id="milestone-category"
            value={newMilestoneCategory}
            onChange={(e) => setNewMilestoneCategory(e.target.value)}
            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-[#2A9D8F] focus:outline-none focus:ring-[#2A9D8F] sm:text-sm"
            required
          >
            <option value="">Select a Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="milestone-deadline" className="block text-sm font-medium text-gray-700">
            Deadline
          </label>
          <input
            id="milestone-deadline"
            type="date"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            value={newMilestoneDeadline}
            onChange={(e) => setNewMilestoneDeadline(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="milestone-document" className="block text-sm font-medium text-gray-700">
            Attach Documents
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <select
              id="milestone-document"
              onChange={handleAddDocument}
              value=""
              className="flex-1 rounded-none rounded-l-md border-gray-300 focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            >
              <option value="">Select a document</option>
              {Object.entries(organizeDocuments()).map(([category, docs]) => (
                <optgroup label={category} key={category}>
                  {docs
                    .filter((doc) => !selectedDocuments.includes(doc._id))
                    .map((doc) => (
                      <option key={doc._id} value={doc._id}>
                        {doc.name} {doc.subCategory ? `(${doc.subCategory})` : ''}
                      </option>
                    ))}
                </optgroup>
              ))}
            </select>
            <button
              type="button"
              className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
              onClick={() => document.getElementById('milestone-document').focus()}
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        {selectedDocuments.length > 0 && (
          <div className="mt-2">
            <h4 className="text-sm font-medium text-gray-700">Selected Documents:</h4>
            <ul className="mt-1 space-y-1">
              {selectedDocuments.map((docId) => {
                const doc = Object.values(documents)
                  .flatMap((category) => Object.values(category).flat())
                  .find((d) => d._id === docId);
                return (
                  <li key={docId} className="flex items-center justify-between text-sm">
                    <span>{doc ? doc.name : 'Unknown Document'}</span>
                    <button type="button" onClick={() => handleRemoveDocument(docId)} className="text-red-600 hover:text-red-800">
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div>
          <button
            type="submit"
            className="w-full inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
          >
            Add Milestone
          </button>
        </div>
      </div>
    </form>
  );
};

export default CustomMilestoneForm;
