import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { createChangeRequest } from '../../../services/changeRequestService';

const ChangeRequestForm = ({ projectId, userEmail, refetch, documents }) => {
  const [filteredDocs, setFilteredDocs] = useState({});
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');

  useEffect(() => {
    const visibleDocs = {};
    Object.entries(documents).forEach(([category, subcategories]) => {
      visibleDocs[category] = {};
      Object.entries(subcategories).forEach(([subcategory, docs]) => {
        const filteredSubcategoryDocs = docs.filter((doc) => doc.visibleToClient === true);
        if (filteredSubcategoryDocs.length > 0) {
          visibleDocs[category][subcategory] = filteredSubcategoryDocs;
        }
      });
      if (Object.keys(visibleDocs[category]).length === 0) {
        delete visibleDocs[category];
      }
    });
    setFilteredDocs(visibleDocs);
  }, [documents]);

  const handleSubmit = async (values, actions) => {
    try {
      // only add non-empty action items
      const actionItems = values.actionItems.filter((item) => item.description !== '');

      const { title, description, isDocumentRelated } = values;

      const payload = {
        projectId,
        title,
        description,
        uploadedBy: userEmail,
        actionItems,
        isDocumentRelated,
      };

      await createChangeRequest(payload);
      refetch();
      actions.resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    actions.setSubmitting(false);
  };

  const handleDocumentChange = (setFieldValue, docId) => {
    let selectedDoc;
    Object.values(filteredDocs).some((subcategories) =>
      Object.values(subcategories).some((docs) => {
        selectedDoc = docs.find((doc) => doc._id === docId);
        return selectedDoc;
      })
    );

    if (selectedDoc) {
      setFieldValue('relatedDocument', docId);
      setFieldValue('title', selectedDoc.name);
      setSelectedDocumentUrl(selectedDoc.signedUrl);
    } else {
      setFieldValue('relatedDocument', '');
      setFieldValue('title', '');
      setSelectedDocumentUrl('');
    }
  };

  const openSignedUrl = () => {
    if (selectedDocumentUrl) {
      window.open(selectedDocumentUrl, '_blank');
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Change Request Form</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Please fill in the details of the change request.</p>
        </div>
        <Formik
          initialValues={{
            title: '',
            description: '',
            actionItems: [{ description: '', status: 'Not Started' }],
            isDocumentRelated: false,
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-6">
                <label className="block text-sm font-medium leading-6 text-gray-900">Is this change related to a specific document?</label>
                <div className="flex items-center mt-2">
                  <Field
                    type="checkbox"
                    className=" bg-gray-300 rounded border-gray-300 text-gray-900 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    name="isDocumentRelated"
                    onChange={() => setFieldValue('isDocumentRelated', !values.isDocumentRelated)}
                    checked={values.isDocumentRelated}
                  />
                  <label className="ml-2 text-sm font-medium leading-6 text-gray-900">Yes</label>
                </div>
              </div>

              {values.isDocumentRelated && (
                <div className="sm:col-span-6 flex items-end">
                  <div className="flex-grow">
                    <label htmlFor="relatedDocument" className="block text-sm font-medium text-gray-700">
                      Related Document
                    </label>
                    <p className="mt-1 text-sm text-gray-500">Please select a document related to your change request.</p>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <Field
                        as="select"
                        name="relatedDocument"
                        id="relatedDocument"
                        className="block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        onChange={(e) => handleDocumentChange(setFieldValue, e.target.value)}
                      >
                        <option value="">Select a document</option>
                        {Object.entries(filteredDocs).map(([category, subcategories]) => (
                          <optgroup key={category} label={category}>
                            {Object.entries(subcategories).map(([subcategory, docs]) => (
                              <React.Fragment key={`${category}-${subcategory}`}>
                                {subcategory !== '' && <option disabled>-- {subcategory} --</option>}
                                {docs.map((doc) => (
                                  <option key={doc._id} value={doc._id}>
                                    {doc.name}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </optgroup>
                        ))}
                      </Field>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-4 py-2 px-4 bg-[#2A9D8F] text-white rounded-md hover:bg-[#2A9D8F] active:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] transition duration-150 ease-in-out"
                    onClick={openSignedUrl}
                    disabled={!selectedDocumentUrl}
                  >
                    Open
                  </button>
                </div>
              )}
              <div className="sm:col-span-6">
                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                  Title *
                </label>
                <Field
                  name="title"
                  id="title"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                  Description *
                </label>
                <Field
                  as="textarea"
                  name="description"
                  id="description"
                  rows="4"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="actionItems" className="block text-sm font-medium leading-6 text-gray-900">
                  Action Items
                </label>
                <FieldArray name="actionItems">
                  {({ remove, push }) => (
                    <div>
                      {values.actionItems.map((item, index) => (
                        <div key={index} className="flex items-center space-x-2 mt-2">
                          <Field
                            name={`actionItems.${index}.description`}
                            placeholder="Description"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className="block w-full sm:w-auto bg-[#2A9D8F] text-white py-1 px-4 rounded-md hover:bg-[#2A9D8F] active:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] transition duration-150 ease-in-out"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => push({ description: '', status: 'Not Started' })}
                        className="text-xs mt-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium px-5 py-2 rounded-lg shadow-sm hover:shadow-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                      >
                        Add Action Item
                      </button>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className="col-span-full">
                <button
                  type="submit"
                  className="block w-full sm:w-auto bg-[#2A9D8F] text-white py-2 px-4 rounded-md hover:bg-[#2A9D8F] active:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] transition duration-150 ease-in-out"
                >
                  Submit Change Request
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeRequestForm;
