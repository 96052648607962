import React, { useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from '../../../App';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { SubMilestoneForm, MilestoneForm, MilestoneList, CSVUploadForm, TemplateMilestoneForm } from '../../Projects/Milestones';
import { PlusIcon } from '@heroicons/react/20/solid';
import useGetProjectMilestones from '../../Hooks/useGetProjectMilestones';
import {
  createMilestone,
  updateMilestone,
  deleteMilestone,
  createSubMilestone,
  updateSubMilestone,
  deleteSubMilestone,
  uploadMilestones,
  addMilestoneTemplate,
} from '../../../services/milestoneService';
import { toast } from 'react-toastify';

// Define the stages
export const stages = ['Creation Pack', 'Home Design Pack', 'Construction Pack', 'Planning Permission', 'Onsite Construction'];

export const categories = ['Design Milestones', 'Permissions Milestones', 'Support Milestones', 'Onsite Milestones'];

function formatDate(inputDate) {
  if (!inputDate) return 'Not completed';

  const date = new Date(inputDate);
  if (isNaN(date.getTime())) return 'Invalid date';

  const day = String(date.getDate()).padStart(2, '0');
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

const ProjectMilestonesPage = () => {
  const { projectId } = useParams();
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(false);
  const { userContextData } = useContext(UserContext);
  const { loading, error, milestones: initialMilestones, refetch: fetchMilestones } = useGetProjectMilestones(projectId);
  const [localMilestones, setLocalMilestones] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [newMilestoneTitle, setNewMilestoneTitle] = useState('');
  const [newMilestoneCategory, setNewMilestoneCategory] = useState('');
  const [newMilestoneStage, setNewMilestoneStage] = useState('');
  const [newMilestoneDeadline, setNewMilestoneDeadline] = useState('');
  const [keyMilestoneId, setKeyMilestoneId] = useState('');
  const [newSubMilestoneTitle, setNewSubMilestoneTitle] = useState('');
  const [newSubMilestoneStage, setNewSubMilestoneStage] = useState('');
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [milestonesKey, setMilestonesKey] = useState(0);

  useEffect(() => {
    if (!loading && !error) {
      setLocalMilestones(initialMilestones);
    }
  }, [loading, error, initialMilestones]);

  const updateLocalMilestone = (updatedMilestone) => {
    setLocalMilestones((prevMilestones) => prevMilestones.map((milestone) => (milestone._id === updatedMilestone._id ? updatedMilestone : milestone)));
  };

  const handleStatusChange = async (milestoneId, subMilestoneId = null) => {
    try {
      let result;
      const currentDate = new Date().toISOString();

      if (subMilestoneId) {
        const milestone = localMilestones.find((m) => m._id === milestoneId);
        const subMilestone = milestone.subMilestones.find((sm) => sm._id === subMilestoneId);
        const newStatus = !subMilestone.status;
        result = await updateSubMilestone(milestoneId, subMilestoneId, {
          status: newStatus,
          completedOn: newStatus ? currentDate : null,
        });
      } else {
        const milestone = localMilestones.find((m) => m._id === milestoneId);
        const newStatus = !milestone.status;

        result = await updateMilestone(milestoneId, {
          status: newStatus,
          completedOn: newStatus ? currentDate : null,
        });
      }

      if (!result) {
        throw new Error('No result returned from update operation');
      }

      // Update local state without creating a new milestone
      setLocalMilestones((prevMilestones) =>
        prevMilestones.map((milestone) =>
          milestone._id === milestoneId
            ? {
                ...milestone,
                ...(subMilestoneId
                  ? {
                      subMilestones: milestone.subMilestones.map((subMilestone) =>
                        subMilestone._id === subMilestoneId
                          ? { ...subMilestone, status: !subMilestone.status, completedOn: !subMilestone.status ? currentDate : null }
                          : subMilestone
                      ),
                    }
                  : { status: !milestone.status, completedOn: !milestone.status ? currentDate : null }),
              }
            : milestone
        )
      );
    } catch (error) {
      console.error('Failed to update status:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      setErrorMessage('Failed to update status: ' + error.message);
    }
  };

  const refreshMilestones = useCallback(() => {
    setMilestonesKey((prevKey) => prevKey + 1);
  }, []);

  const handleAddMilestone = async (milestoneData) => {
    try {
      const newMilestone = await createMilestone({ ...milestoneData, projectId });

      setLocalMilestones((prevMilestones) => [...prevMilestones, newMilestone]);
      refreshMilestones();

      // Reset form fields
      setNewMilestoneTitle('');
      setNewMilestoneCategory('');
      setNewMilestoneDeadline('');
    } catch (error) {
      console.error('Error adding milestone:', error);
      setErrorMessage('Failed to add milestone: ' + error.message);
    }
  };

  const handleAddSubMilestone = async (subMilestoneData) => {
    try {
      const updatedMilestone = await createSubMilestone(subMilestoneData.milestoneId, {
        title: subMilestoneData.title,
        projectId: projectId,
      });

      setLocalMilestones((prevMilestones) =>
        prevMilestones.map((milestone) =>
          milestone._id === subMilestoneData.milestoneId
            ? {
                ...updatedMilestone,
                subMilestones: updatedMilestone.subMilestones,
              }
            : milestone
        )
      );

      // Reset form fields
      setNewSubMilestoneTitle('');
      setKeyMilestoneId('');
    } catch (error) {
      console.error('Error adding sub-milestone:', error);
      setErrorMessage('Failed to add sub-milestone: ' + error.message);
    }
  };

  const handleAddTemplateMilestone = async (templateData) => {
    try {
      let newMilestones;
      if (Array.isArray(templateData)) {
        // Handle multiple milestones
        newMilestones = await Promise.all(templateData.map((data) => addMilestoneTemplate(projectId, data)));
      } else {
        // Handle single milestone
        newMilestones = [await addMilestoneTemplate(projectId, templateData)];
      }

      // Update the local state with the new milestones
      setLocalMilestones((prevMilestones) => [
        ...prevMilestones,
        ...newMilestones.map((milestone) => ({
          _id: milestone._id,
          title: milestone.title,
          category: milestone.category,
          deadline: milestone.deadline,
          status: milestone.status,
          completedOn: milestone.completedOn,
          subMilestones: milestone.subMilestones.map((sub) => ({
            _id: sub._id,
            title: sub.title,
            status: sub.status,
            completedOn: sub.completedOn,
          })),
        })),
      ]);

      // Optionally, you can trigger a refresh of the milestones list
      refreshMilestones();

      // Show success toast
      toast.success(`${newMilestones.length} milestone(s) added successfully!`, {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error adding milestone template(s):', error);
      setErrorMessage('Failed to add milestone template(s): ' + error.message);

      // Show error toast
      toast.error('Failed to add milestone template(s). Please try again.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleDeleteMilestone = async (milestoneId) => {
    try {
      await deleteMilestone(milestoneId);
      await fetchMilestones();
    } catch (error) {
      setErrorMessage('Failed to delete milestone');
    }
  };

  const handleDeleteSubMilestone = async (milestoneId, subMilestoneId) => {
    try {
      await deleteSubMilestone(milestoneId, subMilestoneId);
      await fetchMilestones();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCSVUpload = async (file) => {
    try {
      const newMilestones = await uploadMilestones(projectId, file);
      setLocalMilestones((prevMilestones) => [...prevMilestones, ...newMilestones]);
    } catch (error) {
      console.error('Error uploading CSV:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (loading) {
    return <div>Loading milestones...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {!loading && !error && (
        <>
          <MilestoneList
            key={milestonesKey}
            categories={categories}
            milestones={localMilestones}
            handleStatusChange={handleStatusChange}
            handleDeleteMilestone={handleDeleteMilestone}
            handleDeleteSubMilestone={handleDeleteSubMilestone}
            formatDate={formatDate}
            updateMilestone={updateLocalMilestone}
          />

          <div className="relative my-10">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <button
                type="button"
                onClick={() => setShowUploadForm(!showUploadForm)}
                className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {showUploadForm ? 'Hide Upload Form' : 'Upload New Milestone(s)'}
              </button>
            </div>
          </div>

          {showUploadForm && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-xl font-medium text-gray-900 mb-4">Add Custom Milestone</h3>
                  <MilestoneForm
                    projectId={projectId}
                    onMilestoneAdded={handleAddMilestone}
                    newMilestoneTitle={newMilestoneTitle}
                    setNewMilestoneTitle={setNewMilestoneTitle}
                    newMilestoneCategory={newMilestoneCategory}
                    setNewMilestoneCategory={setNewMilestoneCategory}
                    newMilestoneDeadline={newMilestoneDeadline}
                    setNewMilestoneDeadline={setNewMilestoneDeadline}
                  />
                </div>

                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-xl font-medium text-gray-900 mb-4">Add Sub-Milestone</h3>
                  {localMilestones.length > 0 ? (
                    <SubMilestoneForm
                      milestones={localMilestones}
                      handleAddSubMilestone={handleAddSubMilestone}
                      newSubMilestoneTitle={newSubMilestoneTitle}
                      setNewSubMilestoneTitle={setNewSubMilestoneTitle}
                      keyMilestoneId={keyMilestoneId}
                      setKeyMilestoneId={setKeyMilestoneId}
                    />
                  ) : (
                    <p className="text-gray-600">No milestones available. Add a milestone first to create sub-milestones.</p>
                  )}
                </div>
              </div>

              <div className="mb-8 p-6 bg-white rounded-lg shadow-md mt-10">
                <h3 className="text-xl font-medium text-gray-900 mb-4">Add from Template</h3>
                <TemplateMilestoneForm projectId={projectId} onMilestoneAdded={handleAddTemplateMilestone} />
              </div>

              <div className="mt-8 p-6 bg-white rounded-lg shadow-md">
                <h3 className="text-xl font-medium text-gray-900 mb-4">Upload Milestones from CSV</h3>
                <CSVUploadForm onFileUpload={handleCSVUpload} projectId={projectId} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectMilestonesPage;
