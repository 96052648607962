import React from 'react';
import { toast } from 'react-toastify';

export const SubMilestoneForm = ({ milestones, handleAddSubMilestone, newSubMilestoneTitle, setNewSubMilestoneTitle, keyMilestoneId, setKeyMilestoneId }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleAddSubMilestone({
        title: newSubMilestoneTitle,
        milestoneId: keyMilestoneId,
      });

      // Show success toast
      toast.success('Sub-milestone added successfully!', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // The form fields will be reset in the parent component after the sub-milestone is added
    } catch (error) {
      console.error('Error adding sub-milestone:', error);
      // Show error toast
      toast.error('Failed to add sub-milestone. Please try again.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="w-full">
      <h2 className="font-medium text-gray-900">Add New Sub-Milestone</h2>
      <p className="mt-1 text-sm text-gray-600">Enter details for the submilestone</p>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="space-y-4">
          <div>
            <label htmlFor="key-milestone" className="block text-sm font-medium text-gray-700">
              Key Milestone *
            </label>
            <select
              id="key-milestone"
              value={keyMilestoneId}
              onChange={(e) => setKeyMilestoneId(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-[#2A9D8F] focus:outline-none focus:ring-[#2A9D8F] sm:text-sm"
              required
            >
              <option value="">Select a Key Milestone</option>
              {milestones.map((milestone) => (
                <option key={milestone._id} value={milestone._id}>
                  {milestone.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="sub-milestone-title" className="block text-sm font-medium text-gray-700">
              Sub-Milestone Title *
            </label>
            <input
              id="sub-milestone-title"
              type="text"
              value={newSubMilestoneTitle}
              onChange={(e) => setNewSubMilestoneTitle(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
              required
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
            >
              Add Sub-Milestone
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubMilestoneForm;
