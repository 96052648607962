import React, { useEffect, useState } from 'react';
import { FieldArray, Field, ErrorMessage, useFormikContext } from 'formik';
import { organizationService } from '../../../services/organizationService';
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ShareProjectInfo = (userContextData) => {
  const { values, setFieldValue } = useFormikContext();
  const [organizationMembers, setOrganizationMembers] = useState([]);

  const organizationId = userContextData.userContextData.organisation;

  useEffect(() => {
    const fetchOrganizationMembers = async () => {
      try {
        const organization = await organizationService.getOrganization(organizationId);
        const members = organization.members.map((member) => ({
          _id: member.userId._id,
          name: member.userId.name,
          email: member.userId.email,
          role: member.role,
        }));
        setOrganizationMembers(members);
      } catch (error) {
        console.error('Error fetching organization members:', error);
      }
    };

    fetchOrganizationMembers();
  }, [organizationId]);

  const handleSelectAll = (arrayHelpers) => {
    if (values.admins.length === organizationMembers.length) {
      // If all are selected, deselect all
      setFieldValue('admins', []);
    } else {
      // Select all
      const allAdmins = organizationMembers.map((member) => ({
        _id: member._id,
        name: member.name,
        email: member.email,
      }));
      setFieldValue('admins', allAdmins);
    }
  };

  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Share Project</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        Select the admins for this project from your organization members. If you would like to add new organisation members, please go to organisation page.
      </p>

      <div className="mt-10">
        <FieldArray
          name="admins"
          render={(arrayHelpers) => (
            <div className="space-y-6">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => handleSelectAll(arrayHelpers)}
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  {values.admins.length === organizationMembers.length ? 'Deselect All' : 'Select All'}
                </button>
              </div>
              <ul role="list" className="divide-y divide-gray-100">
                {organizationMembers.map((member) => {
                  const isChecked = values.admins.some((admin) => admin._id === member._id);
                  return (
                    <li key={member._id} className="flex items-center justify-between gap-x-6 py-5">
                      <div className="flex min-w-0 gap-x-4">
                        <UserCircleIcon className="h-12 w-12 flex-none rounded-full bg-gray-50 text-gray-400" />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">{member.name}</p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{member.email}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Field
                          type="checkbox"
                          id={`admin-${member._id}`}
                          name="admins"
                          value={member._id}
                          checked={isChecked}
                          onChange={(e) => {
                            if (e.target.checked) {
                              arrayHelpers.push({
                                _id: member._id,
                                name: member.name,
                                email: member.email,
                              });
                            } else {
                              const idx = values.admins.findIndex((admin) => admin._id === member._id);
                              if (idx !== -1) arrayHelpers.remove(idx);
                            }
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                        />
                        <label htmlFor={`admin-${member._id}`} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                          {isChecked ? 'Remove' : 'Add'} as Admin
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        />
        <ErrorMessage name="admins" component="p" className="mt-2 text-sm text-red-600" />
      </div>
    </div>
  );
};

export default ShareProjectInfo;
