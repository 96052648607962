import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { UserContext } from '../../../App';
import { PaperClipIcon, PhotoIcon, PlusIcon, LinkIcon, MagnifyingGlassIcon, FolderIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link, useParams } from 'react-router-dom';
import { uploadDocument, updateDocument, archiveDocument, unarchiveDocument } from '../../../services/documentService';
import useGetProjectDocuments from '../../Hooks/useGetProjectDocuments';
import useGetUserRole from '../../Hooks/useGetUserRole';

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#2A9D8F]"></div>
  </div>
);

const Spinner = () => (
  <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
);

const UploadForm = ({ category, projectId, refetchDocuments, onClose, existingSubfolders }) => {
  const { userContextData } = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [docName, setDocName] = useState('');
  const [docVersion, setDocVersion] = useState('');
  const [isImportant, setIsImportant] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [isFile, setIsFile] = useState(true);
  const [docUrl, setDocUrl] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [newSubCategory, setNewSubCategory] = useState('');
  const [isAddingNewSubCategory, setIsAddingNewSubCategory] = useState(false);
  const [customSubfolders, setCustomSubfolders] = useState([]);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      setDocName(file.name);
    }
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      if (!docName) {
        setDocName(file.name);
      }
    }
  };

  const handleSubCategoryChange = (e) => {
    const value = e.target.value;
    if (value === 'add_new') {
      setIsAddingNewSubCategory(true);
      setNewSubCategory('');
    } else {
      setSubCategory(value);
      setIsAddingNewSubCategory(false);
    }
  };

  // const handleNewSubCategorySubmit = () => {
  //   if (newSubCategory.trim()) {
  //     const newFolder = newSubCategory.trim();
  //     setSubCategory(newFolder);
  //     setCustomSubfolders((prev) => [...prev, newFolder]);
  //     setIsAddingNewSubCategory(false);
  //     setNewSubCategory('');
  //     console.log('New subfolder added:', newFolder);
  //   }
  // };

  const handleUpload = async (event) => {
    event.preventDefault();
    console.log('Upload button clicked');

    if (!selectedFile && !docUrl) {
      console.log('No file or URL selected');
      return;
    }

    let finalSubCategory = subCategory;
    if (isAddingNewSubCategory && newSubCategory.trim()) {
      finalSubCategory = newSubCategory.trim();
      setCustomSubfolders((prev) => [...prev, finalSubCategory]);
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('userInfo', JSON.stringify(userContextData));
    formData.append('name', docName);
    formData.append('category', category); // Use the category prop directly
    formData.append('subCategory', finalSubCategory);
    formData.append('version', docVersion);
    formData.append('isImportant', isImportant);
    formData.append('visibleToClient', true);
    formData.append('sendEmail', sendEmail);

    if (isFile) {
      formData.append('file', selectedFile);
    } else {
      formData.append('url', docUrl);
    }

    try {
      await uploadDocument(projectId, formData);

      onClose();
      refetchDocuments();
    } catch (err) {
      console.error('Error uploading the document', err);
    } finally {
      setUploading(false);
    }
  };

  return (
    <form onSubmit={handleUpload} className="mt-4">
      <div className="space-y-4">
        <div>
          <label htmlFor="docName" className="block text-sm font-medium text-gray-700">
            Document Name *
          </label>
          <input
            type="text"
            id="docName"
            value={docName}
            onChange={(e) => setDocName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="docVersion" className="block text-sm font-medium text-gray-700">
            Document Version
          </label>
          <input
            type="text"
            id="docVersion"
            value={docVersion}
            onChange={(e) => setDocVersion(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="subCategory" className="block text-sm font-medium text-gray-700">
            Subfolder
          </label>
          {isAddingNewSubCategory ? (
            <div className="flex mt-1">
              <input
                type="text"
                value={newSubCategory}
                onChange={(e) => setNewSubCategory(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Enter new subfolder name"
              />
              {/* <button
                type="button"
                onClick={handleNewSubCategorySubmit}
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add
              </button> */}
            </div>
          ) : (
            <select
              id="subCategory"
              value={subCategory}
              onChange={handleSubCategoryChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">No subfolder</option>
              {existingSubfolders
                .filter((folder) => folder !== '')
                .map((folder) => (
                  <option key={folder} value={folder}>
                    {folder}
                  </option>
                ))}
              <option value="add_new">+ Add new subfolder</option>
            </select>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Document Importance</label>
          <select
            value={isImportant ? 'Important' : 'Non-Important'}
            onChange={(e) => setIsImportant(e.target.value === 'Important')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="Important">Important - Requires client approval</option>
            <option value="Non-Important">Non-Important - Does not require client approval</option>
          </select>
        </div>
        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
              className="rounded border-gray-300 text-#2A9D8F] shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-600">Send email notification to project members</span>
          </label>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Upload Type</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="uploadType" value="file" checked={isFile} onChange={() => setIsFile(true)} />
              <span className="ml-2">File</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input type="radio" className="form-radio" name="uploadType" value="link" checked={!isFile} onChange={() => setIsFile(false)} />
              <span className="ml-2">Link</span>
            </label>
          </div>
        </div>
        {isFile ? (
          <div>
            <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
              Upload document
            </label>
            <div
              className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ${dragActive ? 'bg-gray-100' : ''}`}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <div className="space-y-1 text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                <div className="flex flex-col items-center text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-[#2A9D8F] hover:text-[#2A9D8F] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" className="hidden" onChange={handleFileChange} />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
              </div>
            </div>
            {selectedFile && <p className="mt-2 text-sm text-gray-500">Selected file: {selectedFile.name}</p>}
          </div>
        ) : (
          <div>
            <label htmlFor="docUrl" className="block text-sm font-medium text-gray-700">
              Document URL
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                <LinkIcon className="h-5 w-5" aria-hidden="true" />
              </span>
              <input
                type="url"
                name="docUrl"
                id="docUrl"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                placeholder="https://example.com/document"
                value={docUrl}
                onChange={(e) => setDocUrl(e.target.value)}
                required={!isFile}
              />
            </div>
          </div>
        )}
      </div>
      <button
        type="submit"
        disabled={uploading || (!selectedFile && !docUrl)}
        className="mt-4 px-4 py-2 bg-[#2A9D8F] text-white rounded-md hover:bg-[#2A9D8F] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
    </form>
  );
};

const DocumentFolder = ({
  title,
  subTitle,
  description,
  documents,
  category,
  refetchDocuments,
  isUpdating,
  isUploadOpen,
  onToggleUpload,
  handleArchive,
  handleUnarchive,
  userRole,
}) => {
  const { projectId } = useParams();
  const { userContextData } = useContext(UserContext);
  const [expandedSubfolders, setExpandedSubfolders] = useState({});
  const [filteredDocuments, setFilteredDocuments] = useState({});
  const [sortBy, setSortBy] = useState('uploadedDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [archivingDocuments, setArchivingDocuments] = useState({});

  console.log('ProjectId:', projectId);

  const sortDocuments = useCallback(
    (docs, subCategory) => {
      return [...docs].sort((a, b) => {
        if (subCategory === 'Archived') return 0; // Don't sort archived documents
        if (sortBy === 'uploadedDate') {
          return sortOrder === 'asc' ? new Date(a.uploadedDate) - new Date(b.uploadedDate) : new Date(b.uploadedDate) - new Date(a.uploadedDate);
        } else if (sortBy === 'name') {
          return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }
        return 0;
      });
    },
    [sortBy, sortOrder]
  );

  useEffect(() => {
    if (documents && typeof documents === 'object') {
      const sortedDocuments = Object.entries(documents).reduce((acc, [subCategory, docs]) => {
        acc[subCategory] = sortDocuments(docs, subCategory);
        return acc;
      }, {});

      // Separate archived documents
      const archived = sortedDocuments['Archived'] || [];
      delete sortedDocuments['Archived'];

      // Sort other subfolders
      const sortedEntries = Object.entries(sortedDocuments).sort(([a], [b]) => a.localeCompare(b));

      // Add archived documents at the end
      if (archived.length > 0) {
        sortedEntries.push(['Archived', archived]);
      }

      setFilteredDocuments(Object.fromEntries(sortedEntries));
    } else {
      setFilteredDocuments({});
    }
  }, [documents, sortDocuments]);

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('desc'); // Always start with descending order when changing sort field
    }
  };

  const toggleSubfolder = (subCategory) => {
    setExpandedSubfolders((prev) => ({
      ...prev,
      [subCategory]: !prev[subCategory],
    }));
  };

  const handleArchiveClick = async (e, docId, isArchived) => {
    e.preventDefault();
    e.stopPropagation();
    setArchivingDocuments((prev) => ({ ...prev, [docId]: true }));
    try {
      if (isArchived) {
        await handleUnarchive(docId);
      } else {
        await handleArchive(docId);
      }
    } finally {
      setArchivingDocuments((prev) => ({ ...prev, [docId]: false }));
    }
  };

  const renderDocuments = (docs) => (
    <ul className="divide-y divide-gray-200">
      {docs.map((doc) => (
        <li key={doc._id} className="py-4 flex items-center justify-between hover:bg-gray-50 cursor-pointer" onClick={() => handleDocumentClick(doc.signedUrl)}>
          <div className="flex items-center">
            <PaperClipIcon className="h-5 w-5 text-gray-400 mr-3" />
            <div>
              <p className="text-sm font-medium text-gray-900">{doc.name.length > 25 ? doc.name.substring(0, 25) + '...' : doc.name}</p>
              <p className="text-xs text-gray-500">
                {doc.category} - {doc.subCategory}
              </p>
              <p className="text-xs text-gray-500">Uploaded: {new Date(doc.uploadedDate).toLocaleDateString()}</p>
              {doc.isArchived && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-500 text-white">Archived</span>}
            </div>
          </div>
          <div className="ml-4 flex items-center" onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              checked={doc.approvedByClient || false}
              onChange={(e) => handleCheckboxClick(e, doc._id, e.target.checked)}
              className="h-4 w-4 text-[#2A9D8F] border-gray-300 rounded focus:ring-[#2A9D8F]"
              disabled={isUpdating}
            />
            <span className="ml-2 text-xs text-gray-500">Client Approved</span>
            <span className="ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">View</span>
            {/* Archive/Unarchive Button */}
            {userRole !== 'client' && (
              <button
                onClick={(e) => handleArchiveClick(e, doc._id, doc.isArchived)}
                disabled={archivingDocuments[doc._id]}
                className={`ml-4 text-xs font-medium ${doc.isArchived ? 'text-green-500 hover:underline' : 'text-green-500 hover:underline'} ${
                  archivingDocuments[doc._id] ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {archivingDocuments[doc._id] ? <Spinner /> : doc.isArchived ? 'Unarchive' : 'Archive'}
              </button>
            )}
          </div>
        </li>
      ))}
    </ul>
  );

  const handleDocumentClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleApprovalChange = async (documentId, isApproved) => {
    try {
      await updateDocument(projectId, documentId, { approvedByClient: isApproved });
      refetchDocuments();
    } catch (err) {
      console.error('Error updating document approval status', err);
    }
  };

  const handleCheckboxClick = (e, documentId, isChecked) => {
    e.preventDefault();
    e.stopPropagation();
    handleApprovalChange(documentId, isChecked);
  };

  const existingSubfolders = useMemo(() => {
    const folders = ['', ...new Set(Object.keys(documents))];
    return [...new Set(folders)];
  }, [documents]);

  const totalDocuments = Object.values(filteredDocuments).flat().length;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-6 py-6 sm:px-8">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-xl leading-6 font-semibold text-gray-900">{title}</h3>
            <p className="mt-2 text-sm text-gray-600">
              {totalDocuments} {subTitle}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handleSort('uploadedDate')}
              className={`px-3 py-1 text-sm font-medium rounded ${sortBy === 'uploadedDate' ? 'bg-[#2A9D8F] text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Date {sortBy === 'uploadedDate' && (sortOrder === 'asc' ? '↑' : '↓')}
            </button>
            <button
              onClick={() => handleSort('name')}
              className={`px-3 py-1 text-sm font-medium rounded ${sortBy === 'name' ? 'bg-[#2A9D8F] text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              Name {sortBy === 'name' && (sortOrder === 'asc' ? '↑' : '↓')}
            </button>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 px-6 py-6 sm:px-8">
        <p className="text-sm text-gray-500 mb-4">{description}</p>
        {Object.entries(filteredDocuments).map(([subCategory, docs]) => (
          <div key={subCategory} className="mb-4">
            {subCategory && (
              <div
                className={`flex items-center justify-between cursor-pointer ${subCategory === 'Archived' ? 'text-gray-500' : ''}`}
                onClick={() => toggleSubfolder(subCategory)}
              >
                <div className="flex items-center">
                  {expandedSubfolders[subCategory] ? (
                    <ChevronDownIcon className="h-5 w-5 text-gray-400 mr-2" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5 text-gray-400 mr-2" />
                  )}
                  <FolderIcon className={`h-5 w-5 mr-2 ${subCategory === 'Archived' ? 'text-gray-400' : 'text-[#2A9D8F]'}`} />
                  <span className={`text-sm font-medium ${subCategory === 'Archived' ? 'text-gray-500' : 'text-gray-900'}`}>{subCategory}</span>
                </div>
                <span className="text-xs text-gray-500">
                  {docs.length} document{docs.length !== 1 ? 's' : ''}
                </span>
              </div>
            )}
            {(!subCategory || expandedSubfolders[subCategory]) && renderDocuments(docs)}
          </div>
        ))}
        <div className="relative mt-10">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <button
              type="button"
              onClick={onToggleUpload}
              className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {isUploadOpen ? 'Cancel Upload' : 'Upload New Document'}
            </button>
          </div>
        </div>
        {isUploadOpen && (
          <UploadForm
            key={`upload-form-${category}`}
            category={category}
            projectId={projectId}
            refetchDocuments={refetchDocuments}
            onClose={onToggleUpload}
            existingSubfolders={existingSubfolders}
          />
        )}
      </div>
    </div>
  );
};

const ListDocuments = () => {
  const { userContextData } = useContext(UserContext);
  const { projectId } = useParams();
  const { documents, loading, error, refetch } = useGetProjectDocuments(projectId);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [openUploadCategory, setOpenUploadCategory] = useState(null);

  const { role: userRole, loading: userRoleLoading, error: userRoleError } = useGetUserRole(projectId, userContextData.user_id);

  const defaultFolders = [
    'Working Documents',
    'Formal Documents',
    'Reference Documents',
    'Onsite Documents',
    'Quotes Documents',
    'Existing Property Documents',
  ];

  const mergeWithDefaultFolders = (documents) => {
    const mergedDocuments = { ...documents };
    defaultFolders.forEach((folder) => {
      if (!mergedDocuments[folder]) {
        mergedDocuments[folder] = {};
      }
    });
    return mergedDocuments;
  };

  const mergedDocuments = useMemo(() => mergeWithDefaultFolders(documents), [documents]);

  useEffect(() => {
    if (documents) {
      let allDocuments = [];
      if (Array.isArray(documents)) {
        allDocuments = documents;
      } else if (typeof documents === 'object') {
        allDocuments = Object.values(documents).flatMap((category) => Object.values(category).flat());
      }

      const filtered = allDocuments.filter(
        (doc) =>
          doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          doc.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (doc.subCategory && doc.subCategory.toLowerCase().includes(searchTerm.toLowerCase()))
      );

      setFilteredDocuments(filtered);
    }
  }, [searchTerm, documents]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDocumentClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleApprovalChange = async (documentId, isApproved) => {
    setIsUpdating(true);
    try {
      await updateDocument(projectId, documentId, { approvedByClient: isApproved });
      await refetch();
    } catch (err) {
      console.error('Error updating document approval status', err);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleToggleUpload = (category) => {
    setOpenUploadCategory((prev) => (prev === category ? null : category));
  };

  const handleArchive = async (documentId) => {
    try {
      await archiveDocument(projectId, documentId);
      await refetch();
    } catch (error) {
      console.error('Error archiving document:', error);
      alert('Failed to archive the document.');
    }
  };

  const handleUnarchive = async (documentId) => {
    try {
      await unarchiveDocument(projectId, documentId);
      await refetch();
    } catch (error) {
      console.error('Error unarchiving document:', error);
      alert('Failed to unarchive the document.');
    }
  };

  const renderDocuments = (docs) => (
    <ul className="divide-y divide-gray-200">
      {docs.map((doc) => (
        <li key={doc._id} className="py-4 flex items-center justify-between hover:bg-gray-50 cursor-pointer" onClick={() => handleDocumentClick(doc.signedUrl)}>
          <div className="flex items-center">
            <PaperClipIcon className="h-5 w-5 text-gray-400 mr-3" />
            <div>
              <p className="text-sm font-medium text-gray-900">{doc.name.length > 25 ? doc.name.substring(0, 25) + '...' : doc.name}</p>
              <p className="text-xs text-gray-500">
                {doc.category} - {doc.subCategory}
              </p>
              <p className="text-xs text-gray-500">Uploaded: {new Date(doc.uploadedDate).toLocaleDateString()}</p>
              {doc.isArchived && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-500 text-white">Archived</span>}
            </div>
          </div>
          <div className="ml-4 flex items-center" onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              checked={doc.approvedByClient || false}
              onChange={(e) => handleApprovalChange(doc._id, e.target.checked)}
              className="h-4 w-4 text-[#2A9D8F] border-gray-300 rounded focus:ring-[#2A9D8F]"
              disabled={isUpdating}
            />
            <span className="ml-2 text-xs text-gray-500">Client Approved</span>
            <span className="ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">View</span>
            {/* Archive/Unarchive Button */}
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                doc.isArchived ? handleUnarchive(doc._id) : handleArchive(doc._id);
              }}
              disabled={isUpdating}
              className={`ml-4 text-xs font-medium ${doc.isArchived ? 'text-green-500 hover:underline' : 'text-red-500 hover:underline'} ${
                isUpdating ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isUpdating ? <Spinner /> : doc.isArchived ? 'Unarchive' : 'Archive'}
            </button>
          </div>
        </li>
      ))}
    </ul>
  );

  if (loading) {
    return <div>Loading documents...</div>;
  }

  if (error) {
    return <div>Error loading documents: {error.message}</div>;
  }

  return (
    <div className="space-y-10">
      {isUpdating && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-[#2A9D8F]"></div>
        </div>
      )}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-6 sm:px-8">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-xl leading-6 font-semibold text-gray-900">Documents</h3>
              <p className="mt-2 text-sm text-gray-600">These are the documents for this project.</p>
            </div>
            <div className="flex items-center space-x-4">
              <div className="relative w-64">
                <input
                  type="text"
                  placeholder="Search documents..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md focus:ring-[#2A9D8F] focus:border-[#2A9D8F]"
                />
                <MagnifyingGlassIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {searchTerm ? (
        <div className="mt-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="px-6 py-6 sm:px-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Search Results</h3>
              <ul className="mt-4 divide-y divide-gray-200">
                {filteredDocuments.map((doc) => (
                  <li
                    key={doc._id}
                    className="py-4 flex items-center justify-between hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleDocumentClick(doc.signedUrl)}
                  >
                    <div className="flex items-center">
                      <PaperClipIcon className="h-5 w-5 text-gray-400 mr-3" />
                      <div>
                        <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                        <p className="text-xs text-gray-500">
                          {doc.category} - {doc.subCategory}
                        </p>
                        <p className="text-xs text-gray-500">Uploaded: {new Date(doc.uploadedDate).toLocaleDateString()}</p>
                        {doc.isArchived && (
                          <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-500 text-white">Archived</span>
                        )}
                      </div>
                    </div>
                    <div className="ml-4 flex items-center" onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={doc.approvedByClient || false}
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleApprovalChange(doc._id, e.target.checked);
                        }}
                        className="h-4 w-4 text-[#2A9D8F] border-gray-300 rounded focus:ring-[#2A9D8F]"
                        disabled={isUpdating}
                      />
                      <span className="ml-2 text-xs text-gray-500">Client Approved</span>
                      <span className="ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">View</span>
                      {/* Archive/Unarchive Button */}
                      {userRole != 'client' && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            doc.isArchived ? handleUnarchive(doc._id) : handleArchive(doc._id);
                          }}
                          disabled={isUpdating}
                          className={`ml-4 text-xs font-medium ${doc.isArchived ? 'text-green-500 hover:underline' : 'text-red-500 hover:underline'} ${
                            isUpdating ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          {isUpdating ? <Spinner /> : doc.isArchived ? 'Unarchive' : 'Archive'}
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              {filteredDocuments.length === 0 && <p className="mt-4 text-sm text-gray-500">No documents found matching your search.</p>}
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {Object.entries(mergedDocuments).map(([category, subCategories]) => (
            <DocumentFolder
              key={category}
              title={category}
              subTitle={`${category}`}
              description={`These are the ${category.toLowerCase()} for this project.`}
              documents={subCategories}
              category={category}
              refetchDocuments={refetch}
              isUpdating={isUpdating}
              isUploadOpen={openUploadCategory === category}
              onToggleUpload={() => handleToggleUpload(category)}
              handleArchive={handleArchive}
              handleUnarchive={handleUnarchive}
              userRole={userRole}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ListDocuments;
