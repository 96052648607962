import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../App';
import OrganisationProfile from '../Organisations/Profile';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../utils/api';

const secondaryNavigation = [
  { name: 'Profile', href: '#profile', current: true },
  // { name: 'Projects', href: '#projects', current: false },
  { name: 'Organisation', href: '#organisation', current: false },
];

const ProfilePage = () => {
  const { user, logout } = useAuth0();
  const { userContextData } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [organisation, setOrganisation] = useState(null);
  const [projects, setProjects] = useState([]);
  const [activeSection, setActiveSection] = useState('profile');
  const [updateError, setUpdateError] = useState(null);

  useEffect(() => {
    fetchUserInfo();
    fetchUserProjects();
    if (userContextData.type === 'Firm') {
      fetchOrganisationInfo();
    }
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${userContextData.auth0_id}`);
      setUserInfo(response.data);
      setFormData(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchOrganisationInfo = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/organisations/${userContextData.organisation}`);
      setOrganisation(response.data);
    } catch (error) {
      console.error('Error fetching organisation info:', error);
    }
  };

  const fetchUserProjects = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/getUserProjects`, {
        params: { userInfo: JSON.stringify(userContextData) },
      });
      setProjects(response.data.projects);
    } catch (error) {
      console.error('Error fetching user projects:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateError(null);

    // Email change warning
    if (formData.email !== userInfo.email) {
      const confirmChange = window.confirm(
        'Warning: Changing your email will require you to log in again and may affect your access to projects. Are you sure you want to proceed?'
      );
      if (!confirmChange) {
        return;
      }
    }

    try {
      const response = await api.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${user.sub}`, formData);

      if (response.status === 200) {
        setUserInfo(response.data.user);
        setIsEditing(false);

        if (response.data.user.email !== user.email) {
          alert('Email updated successfully. You will now be logged out. Please log in again with your new email.');
          logout({ returnTo: window.location.origin });
        } else {
          alert('User information updated successfully.');
        }
      }
    } catch (error) {
      console.error('Error updating user info:', error);
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setUpdateError(error.response.data.message || 'Invalid input. Please check your information and try again.');
            break;
          case 401:
            setUpdateError('Unauthorized. Please log in again.');
            break;
          case 409:
            setUpdateError('Email already in use. Please choose a different email.');
            break;
          default:
            setUpdateError('An error occurred while updating your information. Please try again later.');
        }
      } else if (error.request) {
        setUpdateError('Network error. Please check your internet connection and try again.');
      } else {
        setUpdateError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  if (!userInfo) return <div>Loading...</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav className="flex overflow-x-auto border-b border-gray-200 py-4">
        <ul className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-600">
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                className={`${activeSection === item.href.slice(1) ? 'text-[#2A9D8F]' : 'text-gray-600'} hover:text-[#2A9D8F] transition-colors duration-200`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveSection(item.href.slice(1));
                }}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className="mt-8 space-y-10">
        {activeSection === 'profile' && (
          <div id="profile" className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Profile Information</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
            </div>
            <div className="border-t border-gray-200">
              {updateError && (
                <div className="px-4 py-3 bg-red-50 text-red-700 border border-red-400 rounded">
                  <p>{updateError}</p>
                </div>
              )}
              {isEditing ? (
                <form onSubmit={handleSubmit} className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name || ''}
                        onChange={handleInputChange}
                        className="mt-1 focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="sm:col-span-3">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email || ''}
                        onChange={handleInputChange}
                        className="mt-1 focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                      <p className="mt-1 text-sm text-gray-500">Warning: changing user email is not recommended.</p>
                    </div>
                    <div className="sm:col-span-3">
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                        Phone number
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        value={formData.phone || ''}
                        onChange={handleInputChange}
                        className="mt-1 focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="mt-5 flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => {
                        setIsEditing(false);
                        setFormData(userInfo);
                        setUpdateError(null);
                      }}
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#258B7A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                    >
                      Save
                    </button>
                  </div>
                </form>
              ) : (
                <dl className="divide-y divide-gray-200">
                  {[
                    { label: 'Full name', value: userInfo.name },
                    { label: 'Email address', value: userInfo.email },
                    { label: 'Phone number', value: userInfo.phone || 'Not provided' },
                  ].map((item) => (
                    <div key={item.label} className="px-4 py-4 sm:px-6">
                      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.value}</dd>
                      </div>
                    </div>
                  ))}
                </dl>
              )}
            </div>
            {!isEditing && (
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={() => setIsEditing(true)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#258B7A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                >
                  Edit Profile
                </button>
              </div>
            )}
          </div>
        )}

        {activeSection === 'projects' && (
          <div id="projects" className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Project Summary</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Overview of your projects.</p>
            </div>
            <div className="border-t border-gray-200">
              {projects.length > 0 ? (
                <ul className="divide-y divide-gray-200">
                  {projects.map((project) => (
                    <li key={project._id} className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-[#2A9D8F] truncate">{project.clientName}</p>
                        <div className="ml-2 flex-shrink-0 flex">
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{project.projectStatus}</p>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex flex-col">
                          <p className="flex items-center text-sm text-gray-500">Job Reference: {project.jobReference || 'N/A'}</p>
                          <p className="flex items-center text-sm text-gray-500">
                            Start Date: {project.projectStartDate ? new Date(project.projectStartDate).toLocaleDateString() : 'Not set'}
                          </p>
                          <p className="flex items-center text-sm text-gray-500">
                            End Date: {project.projectEndDate ? new Date(project.projectEndDate).toLocaleDateString() : 'Not set'}
                          </p>
                          <p className="flex items-center text-sm text-gray-500">Project Manager: {project.projectManager || 'Not assigned'}</p>
                        </div>
                        <div className="mt-2 flex flex-col items-end text-sm text-gray-500 sm:mt-0">
                          <p>Type: {project.type}</p>
                          <p>Style: {project.projectStyle}</p>
                          <p>Total Budget: £{project.totalBudget?.toLocaleString()}</p>
                          <p className="font-semibold">{project.admins.some((admin) => admin.email === userContextData.email) ? 'Admin' : 'Not Admin'}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="px-4 py-5 sm:px-6 text-sm text-gray-500">No projects found.</p>
              )}
            </div>
          </div>
        )}

        {activeSection === 'organisation' && userContextData.type === 'Firm' && organisation && (
          <div id="organisation" className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Organisation Information</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about your organisation.</p>
            </div>
            <div className="border-t border-gray-200">
              <dl className="divide-y divide-gray-200">
                {[
                  { label: 'Organisation name', value: organisation.name },
                  { label: 'Address', value: organisation.address },
                  { label: 'Email', value: organisation.email },
                  { label: 'Phone number', value: organisation.number },
                  { label: 'Website', value: organisation.website },
                  { label: 'Company number', value: organisation.companyNumber },
                  { label: 'VAT number', value: organisation.vatNumber },
                ].map((item) => (
                  <div key={item.label} className="px-4 py-4 sm:px-6">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.value || 'Not provided'}</dd>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
