import React, { useState, useEffect, useContext } from 'react';
import { milestoneService } from '../../../services/milestoneService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { UserContext } from '../../../App';

export const TemplateMilestoneForm = ({ projectId, onMilestoneAdded, organisation }) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedSubMilestones, setSelectedSubMilestones] = useState({});
  const [deadlines, setDeadlines] = useState({});
  const [allTemplates, setAllTemplates] = useState({});

  const { userContextData } = useContext(UserContext);

  useEffect(() => {
    fetchTemplates();
  }, [userContextData.organisation]);

  const fetchTemplates = async () => {
    try {
      const templates = await milestoneService.getMilestoneTemplates(userContextData.organisation);
      const templatesObject = templates.reduce((acc, template) => {
        acc[template._id] = {
          ...template,
          subMilestones: template.subMilestones.map((sm) => sm.title),
        };
        return acc;
      }, {});
      setAllTemplates(templatesObject);
      console.log('All templates:', templatesObject);
    } catch (error) {
      console.error('Error fetching templates:', error);
      toast.error('Failed to fetch templates');
    }
  };

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplates((prev) => {
      let newSelectedTemplates;
      if (prev.includes(templateId)) {
        newSelectedTemplates = prev.filter((id) => id !== templateId);
        // Remove deadline for deselected template
        setDeadlines((prevDeadlines) => {
          const { [templateId]: _, ...rest } = prevDeadlines;
          return rest;
        });
      } else {
        newSelectedTemplates = [...prev, templateId];
      }

      // Update selectedSubMilestones based on the new selection
      if (newSelectedTemplates.length === 1) {
        const subMilestones = allTemplates[newSelectedTemplates[0]].subMilestones;
        setSelectedSubMilestones(
          subMilestones.reduce((acc, subMilestone) => {
            acc[subMilestone] = true;
            return acc;
          }, {})
        );
      } else {
        setSelectedSubMilestones({});
      }

      return newSelectedTemplates;
    });
  };

  const handleSubMilestoneToggle = (subMilestone) => {
    if (selectedTemplates.length === 1) {
      setSelectedSubMilestones((prev) => ({
        ...prev,
        [subMilestone]: !prev[subMilestone],
      }));
    }
  };

  const handleDeadlineChange = (date, templateId, event) => {
    // If an event object is provided, stop its propagation
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    setDeadlines((prev) => ({
      ...prev,
      [templateId]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let templatesData;
      if (selectedTemplates.length === 1) {
        const selectedSubMilestonesList = Object.entries(selectedSubMilestones)
          .filter(([_, isSelected]) => isSelected)
          .map(([title]) => ({ title }));

        templatesData = [
          {
            title: allTemplates[selectedTemplates[0]].title,
            category: allTemplates[selectedTemplates[0]].category,
            description: allTemplates[selectedTemplates[0]].description,
            subMilestones: selectedSubMilestonesList,
            deadline: deadlines[selectedTemplates[0]],
          },
        ];
      } else {
        templatesData = selectedTemplates.map((templateId) => ({
          title: allTemplates[templateId].title,
          category: allTemplates[templateId].category,
          description: allTemplates[templateId].description,
          subMilestones: allTemplates[templateId].subMilestones.map((subMilestone) => ({ title: subMilestone })),
          deadline: deadlines[templateId],
        }));
      }

      await onMilestoneAdded(templatesData);

      // Reset form state
      setSelectedTemplates([]);
      setSelectedSubMilestones({});
      setDeadlines({});
    } catch (error) {
      console.error('Error preparing milestone template:', error);
      toast.error('Failed to add template milestone(s). Please try again.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleTemplateClick = (e, templateId) => {
    // Prevent the click event from bubbling up if it's coming from the DatePicker or its navigation
    if (
      e.target.closest('.react-datepicker-wrapper') ||
      e.target.closest('.react-datepicker') ||
      e.target.closest('.react-datepicker__navigation') ||
      e.target.closest('.react-datepicker__month-container')
    ) {
      return;
    }
    handleTemplateSelect(templateId);
  };

  return (
    <div className="w-full">
      <h2 className="font-semibold mb-4">Select Template(s)</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        {Object.entries(allTemplates).map(([templateId, template]) => (
          <div
            key={templateId}
            onClick={(e) => handleTemplateClick(e, templateId)}
            className={`border rounded-lg p-4 cursor-pointer transition-all ${
              selectedTemplates.includes(templateId) ? 'border-[#2A9D8F] bg-[#2A9D8F] bg-opacity-10' : 'border-gray-200 hover:border-[#2A9D8F]'
            }`}
          >
            <h3 className="font-semibold mb-2">{template.title}</h3>
            <p className="text-sm text-gray-600 mb-2">{template.category}</p>
            <p className="text-sm mb-2">{template.description}</p>
            <p className="text-xs text-gray-500">
              {template.subMilestones.length} sub-milestone{template.subMilestones.length !== 1 ? 's' : ''}
            </p>
            {selectedTemplates.includes(templateId) && (
              <div className="mt-4 pt-2 border-t border-gray-200">
                <div className="flex items-center gap-2">
                  <label className="block text-sm font-medium text-gray-700">Deadline</label>
                  {!deadlines[templateId] && (
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-400"></span>
                    </span>
                  )}
                </div>
                <DatePicker
                  selected={deadlines[templateId]}
                  onChange={(date, event) => handleDeadlineChange(date, templateId, event)}
                  dateFormat="dd/MM/yyyy"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
                  placeholderText="Select a deadline"
                  autoComplete="off"
                  onClickOutside={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onMonthChange={() => {}}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {selectedTemplates.length > 0 && (
        <form onSubmit={handleSubmit} className="space-y-4">
          {selectedTemplates.length === 1 && (
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">Sub-milestones</label>
              </div>
              <div className="space-y-2">
                {allTemplates[selectedTemplates[0]]?.subMilestones.map((subMilestone, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`subMilestone-${index}`}
                      checked={selectedSubMilestones[subMilestone] || false}
                      onChange={() => handleSubMilestoneToggle(subMilestone)}
                      className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                    />
                    <label htmlFor={`subMilestone-${index}`} className="ml-2 block text-sm text-gray-700">
                      {subMilestone}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="w-full inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
            >
              {selectedTemplates.length === 1 ? 'Add Template Milestone' : `Add ${selectedTemplates.length} Milestones`}
            </button>
            {selectedTemplates.length > 1 && <p className="mt-2 text-sm text-gray-500">All submilestones from selected templates will be added.</p>}
          </div>
        </form>
      )}
    </div>
  );
};

export default TemplateMilestoneForm;
