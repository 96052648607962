import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import {
  DocumentTextIcon,
  ChatBubbleLeftEllipsisIcon,
  PencilSquareIcon,
  FlagIcon,
  PhotoIcon,
  ClockIcon,
  ArrowPathIcon,
  UserIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FolderIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon as ChevronRightSolidIcon } from '@heroicons/react/20/solid';
import { Spinner } from '../../components/UI/Spinner';

const DashboardOverviewPage = () => {
  const { userContextData } = useContext(UserContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [projectsPage, setProjectsPage] = useState(1);
  const [actionsPage, setActionsPage] = useState(1);
  const itemsPerPage = 5;

  const API_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get(`${API_URL}/api/dashboard/dashboard/${userContextData.auth0_id}`);
        setDashboardData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data. Please try again later.');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [userContextData.auth0_id, API_URL]);

  const handleProjectClick = (projectId) => {
    navigate(`/dashboard/project/summary/${projectId}`);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner size="lg" color="success" />
      </div>
    );
  if (error) return <div className="text-red-500 text-center font-semibold">{error}</div>;
  if (!dashboardData) return <div className="text-center text-gray-600">No dashboard data available.</div>;

  const getActionIcon = (action) => {
    switch (action.field) {
      case 'Notes':
        return <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Change Requests':
        return <ArrowPathIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Documents':
        return <DocumentTextIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Images':
        return <PhotoIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      default:
        return <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />;
    }
  };

  const getActionBackground = (action) => {
    switch (action.field) {
      case 'Notes':
        return 'bg-yellow-500';
      case 'Change Requests':
        return 'bg-purple-500';
      case 'Documents':
        return 'bg-blue-500';
      case 'Images':
        return 'bg-pink-500';
      default:
        return 'bg-green-500';
    }
  };

  const safelyGetValue = (obj, path, defaultValue = 'N/A') => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || defaultValue;
  };

  const getActionDescription = (action) => {
    if (action.type === 'document') {
      return {
        description: `${safelyGetValue(action, 'user')} uploaded ${safelyGetValue(action, 'itemName')}`,
        section: 'attachments',
      };
    } else if (action.type === 'change') {
      const section = getSectionFromField(action.field);
      return {
        description: `${safelyGetValue(action, 'user')} ${action.action} ${safelyGetValue(action, 'field')}`,
        section,
      };
    }
    return {
      description: 'Unknown action',
      section: 'summary',
    };
  };

  const getSectionFromField = (field) => {
    switch (field) {
      case 'Notes':
        return 'notes';
      case 'Change Requests':
        return 'change-requests';
      case 'Documents':
        return 'attachments';
      case 'Images':
        return 'images';
      case 'Action Items':
        return 'change-requests';
      default:
        return 'summary';
    }
  };

  const handleActionClick = (action) => {
    const projectId = safelyGetValue(action, 'projectId') || safelyGetValue(action, 'projectReference');
    const { section } = getActionDescription(action);

    if (projectId) {
      navigate(`/dashboard/project/${section}/${projectId}`);
    } else {
      console.error('No project ID found for this action');
    }
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const recentProjects = dashboardData.projectsOverview?.recentProjects || [];
  const recentActions = dashboardData.recentActions?.recentActions || [];

  const paginatedProjects = recentProjects.slice((projectsPage - 1) * itemsPerPage, projectsPage * itemsPerPage);
  const paginatedActions = recentActions.slice((actionsPage - 1) * itemsPerPage, actionsPage * itemsPerPage);

  const totalProjectPages = Math.ceil(recentProjects.length / itemsPerPage);
  const totalActionPages = Math.ceil(recentActions.length / itemsPerPage);

  const EmptyState = ({ title, description, icon: Icon }) => (
    <div className="text-center py-12">
      <Icon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    </div>
  );

  return (
    <div className=" p-6">
      <div id="details" className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Beta Dashboard</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Welcome to the beta dashboard for the new project management system. This is work in progress and will be updated. Clients cannot see this dashboard
            yet.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Recent Projects */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg flex flex-col">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Projects</h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0 flex-grow">
            {paginatedProjects.length > 0 ? (
              <ul role="list" className="divide-y divide-gray-200 min-h-[500px]">
                {paginatedProjects.map((project) => (
                  <li
                    key={safelyGetValue(project, '_id')}
                    className="relative flex justify-between gap-x-6 py-5 px-4 hover:bg-gray-50 transition duration-150 cursor-pointer"
                    onClick={() => handleProjectClick(safelyGetValue(project, '_id'))}
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className="h-12 w-12 flex-none rounded-full bg-gray-50 flex items-center justify-center overflow-hidden">
                        {project.selectedImage ? (
                          <img src={project.selectedImage} alt={safelyGetValue(project, 'jobReference')} className="h-full w-full object-cover" />
                        ) : (
                          <div className="h-full w-full flex items-center justify-center bg-[#2A9D8F] text-white">
                            <span className="text-[8px] font-semibold">{safelyGetValue(project, 'jobReference')}</span>
                          </div>
                        )}
                      </div>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-medium text-gray-900">{safelyGetValue(project, 'jobReference')}</p>
                        <div className="mt-1 flex flex-col text-xs text-gray-500">
                          <p>
                            Clients:{' '}
                            {safelyGetValue(project, 'clients', [])
                              .map((client) => safelyGetValue(client, 'name'))
                              .join(', ') || 'N/A'}
                          </p>
                          <p>
                            Admins:{' '}
                            {safelyGetValue(project, 'admins', [])
                              .map((admin) => safelyGetValue(admin, 'name'))
                              .join(', ') || 'N/A'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-4">
                      <div className="hidden sm:flex sm:flex-col sm:items-end">
                        <p className="text-sm text-gray-900">{safelyGetValue(project, 'projectStage')}</p>
                      </div>
                      <ChevronRightSolidIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyState title="No recent projects" description="Your recent projects will appear here." icon={FolderIcon} />
            )}
          </div>
          {totalProjectPages > 1 && (
            <div className="flex justify-between items-center p-4 bg-gray-50 border-t border-gray-200">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setProjectsPage((prev) => Math.max(prev - 1, 1));
                }}
                disabled={projectsPage === 1}
                className="flex items-center text-sm text-[#2A9D8F] hover:text-[#2A9D8F] disabled:text-gray-400"
              >
                <ChevronLeftIcon className="h-5 w-5 mr-1" />
                Previous
              </button>
              <span className="text-sm text-gray-700">
                Page {projectsPage} of {totalProjectPages}
              </span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setProjectsPage((prev) => Math.min(prev + 1, totalProjectPages));
                }}
                disabled={projectsPage === totalProjectPages}
                className="flex items-center text-sm text-[#2A9D8F] hover:text-[#2A9D8F] disabled:text-gray-400"
              >
                Next
                <ChevronRightIcon className="h-5 w-5 ml-1" />
              </button>
            </div>
          )}
        </div>

        {/* Recent Actions Timeline */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg flex flex-col">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Actions</h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0 flex-grow">
            {paginatedActions.length > 0 ? (
              <ul role="list" className="divide-y divide-gray-200 min-h-[500px]">
                {paginatedActions.map((action) => (
                  <li
                    key={safelyGetValue(action, '_id')}
                    className="relative flex justify-between gap-x-6 py-5 px-4 hover:bg-gray-50 transition duration-150 cursor-pointer"
                    onClick={() => handleActionClick(action)}
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className={`h-12 w-12 flex-none rounded-full ${getActionBackground(action)} flex items-center justify-center`}>
                        {getActionIcon(action)}
                      </div>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-medium text-gray-900">{getActionDescription(action).description}</p>
                        <p className="mt-1 text-xs text-gray-500">Project: {safelyGetValue(action, 'projectReference')}</p>
                      </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-4">
                      <div className="hidden sm:flex sm:flex-col sm:items-end">
                        <time dateTime={safelyGetValue(action, 'timestamp')} className="text-sm text-gray-900">
                          {formatTimestamp(safelyGetValue(action, 'timestamp'))}
                        </time>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <EmptyState title="No recent actions" description="Recent actions of your team will appear here." icon={ClockIcon} />
            )}
          </div>
          {totalActionPages > 1 && (
            <div className="flex justify-between items-center p-4 bg-gray-50 border-t border-gray-200">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setActionsPage((prev) => Math.max(prev - 1, 1));
                }}
                disabled={actionsPage === 1}
                className="flex items-center text-sm text-[#2A9D8F] hover:text-[#2A9D8F] disabled:text-gray-400"
              >
                <ChevronLeftIcon className="h-5 w-5 mr-1" />
                Previous
              </button>
              <span className="text-sm text-gray-700">
                Page {actionsPage} of {totalActionPages}
              </span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setActionsPage((prev) => Math.min(prev + 1, totalActionPages));
                }}
                disabled={actionsPage === totalActionPages}
                className="flex items-center text-sm text-[#2A9D8F] hover:text-[#2A9D8F] disabled:text-gray-400"
              >
                Next
                <ChevronRightIcon className="h-5 w-5 ml-1" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverviewPage;
