import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import { UserContext } from '../../../App';
import axios from 'axios';

import useGetProject from '../../Hooks/useGetProject';
import useGetProjectDocuments from '../../Hooks/useGetProjectDocuments';
import useGetTasks from '../../Hooks/useGetTasks';
import useGetProjectChangeRequests from '../../Hooks/useGetProjectChangeRequests';
import useGetProjectContacts from '../../Hooks/useGetProjectContacts';

import { DocumentFolders } from '../../Projects/Attachments';
import { MilestonesTimeline } from '../../Projects/Milestones';
import ChangeRequestsSummary from '../../Projects/ChangeRequests/Summary';
import NotesSummaryV2 from '../../Projects/Notes/NotesSummaryV2';
import Divider from '../../UI/Divider';
import api from '../../../utils/api';
import {
  DocumentTextIcon,
  ChatBubbleLeftEllipsisIcon,
  PencilSquareIcon,
  PhotoIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon as ChevronRightSolidIcon } from '@heroicons/react/20/solid';

function ProjectSummaryPage() {
  const { projectId } = useParams();
  const { userContextData } = useContext(UserContext);
  const [activeSection, setActiveSection] = useState('details');
  const navigate = useNavigate();

  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);

  const [copiedIndex, setCopiedIndex] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [milestones, setMilestones] = useState([]);
  const [recentActions, setRecentActions] = useState([]);
  const [actionsPage, setActionsPage] = useState(1);
  const [totalActionPages, setTotalActionPages] = useState(1);

  const { contacts, loading: contactsLoading, error: contactsError } = useGetProjectContacts(projectId);
  const { changeRequests, loading: changeRequestsLoading, error: changeRequestsError } = useGetProjectChangeRequests(projectId);
  const { project, loading: projectLoading, error: projectError } = useGetProject(projectId);
  const { documents, loading: documentsLoading, error: documentsError } = useGetProjectDocuments(projectId);
  const { loading: tasksLoading, error: tasksError } = useGetTasks(projectId);

  const loading = projectLoading || documentsLoading || tasksLoading || changeRequestsLoading || contactsLoading;
  const error = projectError || documentsError || tasksError || changeRequestsError || contactsError;

  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Not set';
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [imagesResponse, notesResponse, organisationResponse, milestonesResponse, recentActionsResponse] = await Promise.all([
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/images/project/${projectId}/images`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/notes/project/${projectId}/notes`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/organisations/${userContextData.organisation}`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/milestones/project/${projectId}/milestones`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/projects/${projectId}/actions?page=${actionsPage}&limit=5`),
        ]);

        setImages(imagesResponse.data);
        setNotes(notesResponse.data);
        setOrganisation(organisationResponse.data);
        setMilestones(milestonesResponse.data);
        setRecentActions(recentActionsResponse.data.actions);
        setTotalActionPages(recentActionsResponse.data.totalPages);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId, userContextData.organisation, actionsPage]);

  const handleCopyToClipboard = (email, index) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    });
  };

  const getSectionFromField = (field) => {
    switch (field) {
      case 'Notes':
        return 'notes';
      case 'Change Requests':
        return 'change-requests';
      case 'Documents':
        return 'attachments';
      case 'Images':
        return 'images';
      case 'Contacts':
        return 'contacts';
      default:
        return 'summary';
    }
  };

  const handleActionClick = (action) => {
    const section = getSectionFromField(action.field);
    navigate(`/dashboard/project/${section}/${projectId}`);
  };

  if (loading || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner aria-label="Loading" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-4">Error: {error.message}</div>;
  }

  const InfoItem = ({ label, value }) => (
    <div className="px-4 py-4 sm:px-6">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value || 'Not provided'}</dd>
      </div>
    </div>
  );

  const renderInformationTable = (title, data, columns) => (
    <div className="mt-8 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      </div>
      <div className="border-t border-gray-200">
        {data && data.length > 0 ? (
          <dl className="divide-y divide-gray-200">
            {data.map((item, index) => (
              <div key={item?._id || `row-${index}`} className="px-4 py-4 sm:px-6">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {columns.map((column, colIndex) => (
                    <div key={`col-${colIndex}`}>
                      <dt className="text-sm font-medium text-gray-500">{column}</dt>
                      <dd className="mt-1 text-sm text-gray-900">{item && column.toLowerCase() in item ? item[column.toLowerCase()] : 'N/A'}</dd>
                    </div>
                  ))}
                  {title === 'Client Information' && item && item.email && (
                    <div className="sm:col-start-3 sm:text-right">
                      <button onClick={() => handleCopyToClipboard(item.email, index)} className="text-[#2A9D8F] hover:text-[#258B7A]">
                        {copiedIndex === index ? 'Copied!' : 'Copy Email'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </dl>
        ) : (
          <p className="px-4 py-5 sm:px-6 text-sm text-gray-500 italic">No {title.toLowerCase()} available</p>
        )}
      </div>
    </div>
  );

  const renderProjectImages = () => {
    const selectedImages = images.filter((image) => image.selected);
    if (selectedImages.length === 0) return null;

    return (
      <div className="w-48 h-48 overflow-hidden rounded-md shadow-md relative">
        <img src={selectedImages[0].signedUrl} alt="Project Image" className="w-full h-full object-cover" />
        {selectedImages.length > 1 && (
          <div className="absolute bottom-1 right-1 bg-white rounded-full px-2 py-1 text-sm font-semibold text-gray-700">+{selectedImages.length - 1}</div>
        )}
      </div>
    );
  };

  const getActionIcon = (action) => {
    switch (action.field) {
      case 'Notes':
        return <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Change Requests':
        return <ArrowPathIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Documents':
        return <DocumentTextIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Images':
        return <PhotoIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      default:
        return <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />;
    }
  };

  const getActionBackground = (action) => {
    switch (action.field) {
      case 'Notes':
        return 'bg-yellow-500';
      case 'Change Requests':
        return 'bg-purple-500';
      case 'Documents':
        return 'bg-blue-500';
      case 'Images':
        return 'bg-pink-500';
      default:
        return 'bg-green-500';
    }
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const countVisibleDocuments = (documents) => {
    let count = 0;
    Object.values(documents).forEach((category) => {
      Object.values(category).forEach((subcategory) => {
        count += subcategory.filter((doc) => doc.visibleToClient).length;
      });
    });
    return count;
  };

  const navItems = [
    { name: 'Details', href: '#details', count: null },
    { name: 'Milestones', href: '#milestones', count: milestones.length },
    { name: 'Documents', href: '#documents', count: countVisibleDocuments(documents) },
    { name: 'Change Requests', href: '#change-requests', count: changeRequests.length },
    { name: 'Notes', href: '#notes', count: notes.length },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav className="flex overflow-x-auto border-b border-gray-200 py-4">
        <ul className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-600">
          {navItems.map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                className={`${
                  activeSection === item.href.slice(1) ? 'text-[#2A9D8F]' : 'text-gray-600'
                } hover:text-[#2A9D8F] transition-colors duration-200 flex items-center`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveSection(item.href.slice(1));
                }}
              >
                {item.name}
                {item.count !== null && (
                  <span
                    className={`ml-2 px-2 py-0.5 text-xs font-medium rounded-full ${
                      activeSection === item.href.slice(1) ? 'bg-[#2A9D8F] text-white' : 'bg-gray-100 text-gray-600'
                    }`}
                  >
                    {item.count}
                  </span>
                )}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className="mt-8 space-y-10">
        {activeSection === 'details' && (
          <>
            <div id="details" className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:px-6">
                <div className="flex flex-col sm:flex-row justify-between items-start">
                  <div className="flex-grow mb-4 sm:mb-0">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Project Details</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Key information about the project.</p>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Here you can view the project details, milestones, attachments, change requests and notes.
                    </p>
                  </div>
                  <div className="flex-shrink-0">{renderProjectImages()}</div>
                </div>
              </div>
              <div className="border-t border-gray-200">
                <dl className="divide-y divide-gray-200">
                  <InfoItem label="Client Name" value={project?.clients[0]?.name} />
                  <InfoItem label="Job Ref" value={project.jobReference} />
                  <InfoItem label="Manager" value={project.projectManager} />
                  <InfoItem label="Property Address" value={project.propertyAddress} />
                  <InfoItem label="Start Date" value={formatDate(project.projectStartDate)} />
                  <InfoItem label="End Date" value={formatDate(project.projectEndDate)} />
                </dl>
              </div>
            </div>

            {renderInformationTable('Client Information', project.clients, ['Name', 'Email'])}
            {renderInformationTable('Organisation Information', [organisation], ['Name', 'Email', 'Number'])}

            <div id="recent-actions" className="bg-white shadow-lg rounded-lg overflow-hidden mt-6">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Actions</h3>
                <span className="text-sm text-gray-500">
                  Page {actionsPage} of {totalActionPages}
                </span>
              </div>
              <div className="border-t border-gray-200">
                {recentActions.length > 0 ? (
                  <ul role="list" className="divide-y divide-gray-200">
                    {recentActions.map((action) => (
                      <li
                        key={action._id}
                        className="px-4 py-4 sm:px-6 hover:bg-gray-50 transition duration-150 cursor-pointer"
                        onClick={() => handleActionClick(action)}
                      >
                        <div className="flex items-center space-x-4">
                          <div className={`flex-shrink-0 h-10 w-10 rounded-full ${getActionBackground(action)} flex items-center justify-center`}>
                            {getActionIcon(action)}
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {action.user} {action.action} {action.field}
                            </p>
                            {action.itemName && <p className="text-sm text-gray-500 truncate">{action.itemName}</p>}
                          </div>
                          <div className="flex-shrink-0 text-sm text-gray-500">{formatTimestamp(action.timestamp)}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="px-4 py-5 sm:px-6 text-sm text-gray-500 italic">No recent actions available</p>
                )}
              </div>
              <div className="flex justify-between items-center px-4 py-3 border-t border-gray-200 bg-gray-50">
                <button
                  onClick={() => setActionsPage(actionsPage - 1)}
                  disabled={actionsPage === 1}
                  className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  <ChevronLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  Previous
                </button>
                <button
                  onClick={() => setActionsPage(actionsPage + 1)}
                  disabled={actionsPage === totalActionPages}
                  className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  Next
                  <ChevronRightIcon className="h-5 w-5 ml-1" aria-hidden="true" />
                </button>
              </div>
            </div>

            <div id="contacts" className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Project Contacts</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Key contacts for this project.</p>
              </div>
              <div className="border-t border-gray-200">
                {contacts && contacts.length > 0 ? (
                  <dl className="divide-y divide-gray-200">
                    {contacts.map((contact, index) => (
                      <div key={contact._id} className="px-4 py-4 sm:px-6">
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                            <dd className="mt-1 text-sm text-gray-900">{contact.name}</dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                            <dd className="mt-1 text-sm text-gray-900">{contact.email}</dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Phone</dt>
                            <dd className="mt-1 text-sm text-gray-900">{contact.phone}</dd>
                          </div>
                        </div>
                      </div>
                    ))}
                  </dl>
                ) : (
                  <p className="px-4 py-5 sm:px-6 text-sm text-gray-500 italic">No contacts available</p>
                )}
              </div>
              <div className="px-4 py-5 sm:px-6">
                <Link to={`/dashboard/project/contacts/${projectId}`}>
                  <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#258B7A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]">
                    Manage Contacts
                  </button>
                </Link>
              </div>
            </div>
          </>
        )}

        {activeSection === 'milestones' && <MilestonesTimeline />}

        {activeSection === 'documents' && <DocumentFolders documents={documents} />}

        {activeSection === 'change-requests' && (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <ChangeRequestsSummary changeRequests={changeRequests} />
          </div>
        )}

        {activeSection === 'notes' && (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <NotesSummaryV2 notes={notes} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectSummaryPage;
