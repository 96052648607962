import React from 'react';
import AllProjectsTable from '../../Projects/Tables/AllProjectsTable';
import EmptyProjectsMessage from '../../Projects/Common/EmptyProjectsMessage';
import { Spinner } from 'flowbite-react';
import { useProjects } from '../../Hooks/useProjects';
import { useSharedProjects } from '../../Hooks/useSharedProjects';
import { UserContext } from '../../../App';
import { useContext } from 'react';

const ProjectAllTablePage = () => {
  const { userContextData } = useContext(UserContext);
  const { projects, fetchProjects, isLoading: isProjectsLoading } = useProjects(userContextData);
  const { sharedProjects, fetchSharedProjects, isLoading: isSharedProjectsLoading } = useSharedProjects();

  const isLoading = isProjectsLoading || isSharedProjectsLoading;
  const allProjects = [...projects, ...sharedProjects];

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="xl" color="success" aria-label="Loading projects" />
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        {allProjects.length > 0 ? (
          <AllProjectsTable projects={allProjects} fetchProjects={fetchProjects} fetchSharedProjects={fetchSharedProjects} />
        ) : (
          <EmptyProjectsMessage />
        )}
      </div>
    </div>
  );
};

export default ProjectAllTablePage;
