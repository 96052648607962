import { useState, useEffect, useCallback } from 'react';
import api from "../../utils/api";

export function useProjects(userContextData) {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/getUserProjects`, {
        params: { auth0_id: userContextData.auth0_id }
      });
      setProjects(response.data.projects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userContextData.auth0_id]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return { projects, fetchProjects, isLoading };
}

export default useProjects;
