import { format, isValid, parseISO } from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

const getFirstName = (fullName) => {
  // First, check if it's an email address
  if (fullName.includes('@')) {
    return fullName.split('@')[0].charAt(0).toUpperCase() + fullName.split('@')[0].slice(1);
  }
  // If it's not an email, assume it's a full name
  return fullName.split(' ')[0];
};

const renderStatusCell = (status) => {
  let backgroundColor = '';
  let textColor = '';
  let ringColor = '';
  let displayStatus = '';

  switch (status) {
    case 'active':
      backgroundColor = 'bg-green-50';
      textColor = 'text-green-700';
      ringColor = 'ring-green-600';
      displayStatus = 'Active';
      break;
    case 'completed':
      backgroundColor = 'bg-blue-50';
      textColor = 'text-blue-700';
      ringColor = 'ring-blue-600';
      displayStatus = 'Completed';
      break;
    case 'onHold':
      backgroundColor = 'bg-orange-50';
      textColor = 'text-orange-700';
      ringColor = 'ring-orange-600';
      displayStatus = 'On Hold';
      break;
    case 'cancelled':
      backgroundColor = 'bg-red-50';
      textColor = 'text-red-700';
      ringColor = 'ring-red-600';
      displayStatus = 'Cancelled';
      break;
    case 'clientApproved':
      backgroundColor = 'bg-teal-50';
      textColor = 'text-teal-700';
      ringColor = 'ring-teal-600';
      displayStatus = 'Client Approved';
      break;
    case 'clientRejected':
      backgroundColor = 'bg-pink-50';
      textColor = 'text-pink-700';
      ringColor = 'ring-pink-600';
      displayStatus = 'Client Rejected';
      break;
    case 'clientReview':
    default:
      backgroundColor = 'bg-yellow-50';
      textColor = 'text-yellow-700';
      ringColor = 'ring-yellow-600';
      displayStatus = 'Client To Review';
      break;
  }

  return (
    <span className={`inline-flex items-center rounded-md ${backgroundColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}/20`}>
      {displayStatus}
    </span>
  );
};

const renderStageCell = (stage) => {
  const stageColors = {
    'Creation Pack': { bg: 'bg-gray-50', text: 'text-gray-700', ring: 'ring-gray-600' },
    'Home Design Pack': { bg: 'bg-blue-50', text: 'text-blue-700', ring: 'ring-blue-600' },
    'Construction Pack': { bg: 'bg-yellow-50', text: 'text-yellow-700', ring: 'ring-yellow-600' },
    Onsite: { bg: 'bg-green-50', text: 'text-green-700', ring: 'ring-green-600' },
    'On Hold': { bg: 'bg-orange-50', text: 'text-orange-700', ring: 'ring-orange-600' },
    Cancelled: { bg: 'bg-red-50', text: 'text-red-700', ring: 'ring-red-600' },
    Complete: { bg: 'bg-green-50', text: 'text-green-700', ring: 'ring-green-600' },
  };

  const defaultColor = { bg: 'bg-gray-50', text: 'text-gray-700', ring: 'ring-gray-600' };
  const color = stageColors[stage] || defaultColor;

  return (
    <span className={`inline-flex items-center rounded-md ${color.bg} px-2 py-1 text-xs font-medium ${color.text} ring-1 ring-inset ${color.ring}/20`}>
      {stage || 'No Stage'}
    </span>
  );
};

export default function AllProjectsTable({ projects, fetchProjects, fetchSharedProjects }) {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [sortField, setSortField] = useState('jobReference');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(20);
  const minRowsToShow = 1;
  const [stageFilter, setStageFilter] = useState('All Stages');

  const userRole = userContextData?.projects?.length > 0 ? userContextData.projects[0].role : null;

  const handleChooseProject = (projectId, status, role) => {
    const newContextData = {
      ...userContextData,
      selected_project: projectId,
      selected_project_status: status,
    };
    setUserContextData(newContextData);

    const routePath =
      status === 'clientReview' && (role === 'client' || role === 'Client' || role === 'client, client')
        ? `/dashboard/edit-project/${projectId}`
        : `/dashboard/project/summary/${projectId}`;

    navigate(routePath);
    window.scrollTo(0, 0); // Add this line to scroll to the top of the page
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getNumericPart = (jobRef) => {
    const numericPart = jobRef.match(/\d+/);
    return numericPart ? parseInt(numericPart[0], 10) : 0;
  };

  const sortedProjects = [...projects].sort((a, b) => {
    let aValue = a[sortField];
    let bValue = b[sortField];

    if (sortField === 'jobReference') {
      aValue = getNumericPart(a.jobReference);
      bValue = getNumericPart(b.jobReference);
    } else if (sortField === 'clients') {
      aValue = a.clients[0]?.name || '';
      bValue = b.clients[0]?.name || '';
    } else if (sortField === 'projectManager') {
      aValue = getFirstName(a.projectManager);
      bValue = getFirstName(b.projectManager);
    } else if (sortField === 'projectStartDate' || sortField === 'projectEndDate') {
      aValue = new Date(a[sortField]);
      bValue = new Date(b[sortField]);
    }

    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const stageOptions = useMemo(() => {
    const stages = new Set(projects.map((project) => project.projectStage));
    return ['All Stages', ...Array.from(stages)];
  }, [projects]);

  const filteredProjects = sortedProjects.filter(
    (project) =>
      (stageFilter === 'All Stages' || project.projectStage === stageFilter) &&
      (project.jobReference.toLowerCase().includes(searchQuery.toLowerCase()) ||
        project.clients.some((client) => client.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        project.projectManager.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const columns = [
    { label: 'Job Ref.', field: 'jobReference', width: '120px' },
    { label: 'Clients', field: 'clients', width: '180px' },
    { label: 'Manager', field: 'projectManager', width: '100px' },
    { label: 'Stage', field: 'projectStage', width: '150px' },
    { label: 'Start Date', field: 'projectStartDate', width: '110px' },
    { label: 'End Date', field: 'projectEndDate', width: '110px' },
    {
      label: (
        <div className="flex items-center group relative">
          Status
          <QuestionMarkCircleIcon className="ml-1 h-4 w-4 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          <div className="hidden group-hover:block absolute left-1/2 transform -translate-x-1/2 top-full mt-1 w-72 p-2 text-xs bg-white rounded-md shadow-lg border border-gray-200 z-10">
            <p className="text-gray-700">
              This status indicates whether a client has
              <br />
              logged in and started interacting with
              <br />
              the project.
            </p>
          </div>
        </div>
      ),
      field: 'projectStatus',
      width: '140px',
    },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'dd MMM yyyy') : 'Invalid Date';
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="px-4 sm:px-6 lg:px-8 flex-grow flex flex-col">
        <div className="sm:flex sm:items-center sm:justify-between mb-6">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">All Projects</h1>
            <p className="mt-2 text-sm text-gray-700">
              {userRole === 'client' ? 'A list of all projects shared with you' : 'A list of all projects that you and your colleagues created.'}
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:flex-none sm:ml-4 sm:w-1/3">
            <input
              type="text"
              placeholder="Search by Job Ref. or Client Name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="mt-4 sm:mt-0 sm:flex-none sm:ml-4 sm:w-1/4">
            <select
              value={stageFilter}
              onChange={(e) => setStageFilter(e.target.value)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            >
              {stageOptions.map((stage) => (
                <option key={stage} value={stage}>
                  {stage}
                </option>
              ))}
            </select>
          </div>
          {!userRole === 'client' && (
            <div className="mt-4 sm:ml-4 sm:mt-0">
              <Link to="/dashboard/add-project">
                <button
                  type="button"
                  className="rounded-md bg-[#2A9D8F] px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#264653] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2A9D8F]"
                >
                  Add Project
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="flex-grow overflow-hidden border border-gray-200 rounded-lg shadow">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map(({ label, field, width }) => (
                    <th
                      key={field}
                      scope="col"
                      className="px-4 py-3.5 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider cursor-pointer relative"
                      style={{ width, minWidth: width, maxWidth: width }}
                      onClick={() => handleSort(field)}
                    >
                      <div className="flex items-center justify-between">
                        <span>{label}</span>
                        <span className="ml-2">
                          {sortField === field ? (
                            sortDirection === 'asc' ? (
                              <ChevronUpIcon className="h-4 w-4 text-gray-500" />
                            ) : (
                              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                            )
                          ) : (
                            <div className="h-4 w-4" /> // Placeholder to maintain consistent spacing
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentProjects.map((project, index) => {
                  const formattedStartDate = formatDate(project.projectStartDate);
                  const formattedEndDate = formatDate(project.projectEndDate);

                  return (
                    <tr
                      key={index}
                      className="hover:bg-gray-50 transition-colors duration-200 ease-in-out cursor-pointer"
                      onClick={() => handleChooseProject(project._id, project.projectStatus, project.userRoles)}
                    >
                      {columns.map(({ field, width }) => (
                        <td key={field} className="px-4 py-3 text-sm text-gray-500 whitespace-nowrap" style={{ width, minWidth: width, maxWidth: width }}>
                          {field === 'jobReference' && (
                            <div>
                              <div className="font-medium text-gray-900">{project.jobReference}</div>
                              <div className="text-xs text-gray-500">{project.userRoles ? `${project.userRoles}` : 'Role: N/A'}</div>
                            </div>
                          )}
                          {field === 'clients' && (
                            <div className="truncate">
                              {project.clients && project.clients.length > 0 ? (
                                project.clients.map((client, clientIndex) => (
                                  <div key={clientIndex} className="truncate">
                                    {client.name}
                                  </div>
                                ))
                              ) : (
                                <span className="text-gray-400 italic">No clients</span>
                              )}
                            </div>
                          )}
                          {field === 'projectManager' && getFirstName(project.projectManager)}
                          {field === 'projectStage' && renderStageCell(project.projectStage)}
                          {field === 'projectStartDate' && <span>{formattedStartDate}</span>}
                          {field === 'projectEndDate' && <span>{formattedEndDate}</span>}
                          {field === 'projectStatus' && renderStatusCell(project.projectStatus)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {Array.from({ length: Math.max(minRowsToShow - currentProjects.length, 0) }, (_, index) => (
                  <tr key={`empty-${index}`}>
                    <td colSpan={columns.length} className="py-3">
                      &nbsp;
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center text-sm text-gray-700">
          <div>
            {filteredProjects.length > 0 ? (
              <>
                Showing {indexOfFirstProject + 1} to {Math.min(indexOfLastProject, filteredProjects.length)} of {filteredProjects.length} projects
              </>
            ) : (
              <span>No projects found</span>
            )}
          </div>
          {filteredProjects.length > projectsPerPage && (
            <div>
              {Array.from({ length: Math.ceil(filteredProjects.length / projectsPerPage) }, (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-[#2A9D8F] text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
