import React, { useState, useContext } from 'react';
import { Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { UserContext } from '../../../App';
import { updateProject } from '../../../services/projectService';
import { ExclamationTriangleIcon, CheckCircleIcon, UserGroupIcon, ChevronDownIcon, ChevronUpIcon, PlusCircleIcon } from '@heroicons/react/20/solid';

const ClientInfoSection = ({ disableUpdateEmails, onUpdateClients, projectId, project, enableClientUpdates }) => {
  const { values, setFieldValue } = useFormikContext();
  const { userContextData, userRole } = useContext(UserContext);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  React.useEffect(() => {
    if (disableUpdateEmails || !values || !values.clients) {
      return;
    }

    const clientEmails = values.clients.filter((client) => client.email).map((client) => client.email);
    setFieldValue(
      'shareWithEmails',
      clientEmails.map((email) => ({ email, role: 'client' }))
    );
  }, [values?.clients, disableUpdateEmails, setFieldValue]);

  const handleSaveClients = async () => {
    if (!values || !values.clients) {
      console.error('No clients data available');
      return;
    }
    setIsSaving(true);
    try {
      await onUpdateClients(values.clients);
      setShowSuccess(true);
      setShowWarning(false);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error('Error saving clients:', error);
      setShowWarning(true);
    } finally {
      setIsSaving(false);
    }
  };

  React.useEffect(() => {
    if (showWarning) {
      setShowSuccess(false);
    }
  }, [showWarning]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!values) {
    return <div>Loading client information...</div>;
  }

  return (
    <div className="border-b border-gray-900/10 pb-12">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Client Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Please enter the client's information.</p>
          <p className="mt-2 text-sm leading-6 text-gray-500 italic">Note: You can add or update clients later if needed.</p>
        </div>
        {!isExpanded && (
          <button
            type="button"
            onClick={toggleExpand}
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <UserGroupIcon className="h-5 w-5 mr-2 inline" />
            Manage Clients
            <ChevronDownIcon className="h-5 w-5 ml-2 inline" />
          </button>
        )}
      </div>

      <div className="mt-10">
        {isExpanded && (
          <FieldArray
            name="clients"
            render={(arrayHelpers) => (
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Manage Clients</h3>
                  <button
                    type="button"
                    onClick={toggleExpand}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <ChevronUpIcon className="h-5 w-5 mr-1 inline" />
                    Close
                  </button>
                </div>
                {(!values.clients || values.clients.length === 0) && (
                  <div className="mb-4">
                    <button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({ name: '', email: '', phone: '', address: '' });
                        setShowWarning(true);
                      }}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#1c3640] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#264653]"
                    >
                      <PlusCircleIcon className="h-5 w-5 mr-2" />
                      Add Client
                    </button>
                  </div>
                )}
                {values.clients &&
                  values.clients.map((client, index) => (
                    <div key={index} className="mb-6 p-4 border rounded">
                      <h3 className="text-lg font-semibold mb-2">Client {index + 1}</h3>
                      {['name', 'email', 'phone', 'address'].map((field) => (
                        <div key={field} className="mb-2">
                          <label htmlFor={`clients.${index}.${field}`} className="block text-sm font-medium leading-6 text-gray-900">
                            {field.charAt(0).toUpperCase() + field.slice(1)}
                          </label>
                          <Field
                            name={`clients.${index}.${field}`}
                            type={field === 'email' ? 'email' : 'text'}
                            placeholder={getPlaceholder(field)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#264653] sm:text-sm sm:leading-6"
                          />
                          <ErrorMessage name={`clients.${index}.${field}`} component="div" className="text-xs text-red-500 italic" />
                        </div>
                      ))}
                      <div className="mt-2 flex justify-between items-center">
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="inline-flex items-center px-4 py-2 text-gray-900 border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#264653] hover:bg-gray-200 rounded sm:text-sm sm:leading-6"
                        >
                          Remove Client
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            arrayHelpers.insert(index + 1, { name: '', email: '', phone: '', address: '' });
                            setShowWarning(true);
                          }}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#1c3640] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#264653]"
                        >
                          <PlusCircleIcon className="h-5 w-5 mr-2" />
                          Add Another Client
                        </button>
                      </div>
                    </div>
                  ))}
                {enableClientUpdates && values.clients && values.clients.length > 0 && (
                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      onClick={handleSaveClients}
                      disabled={isSaving}
                      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                        isSaving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#264653] hover:bg-[#1c3640]'
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]`}
                    >
                      {isSaving ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Saving...
                        </>
                      ) : (
                        'Save Clients'
                      )}
                    </button>
                  </div>
                )}
                {showWarning && (
                  <div className="mt-4 rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>You have unsaved changes. Please click "Save Clients" or "Create Project" to save your changes.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showSuccess && (
                  <div className="mt-4 rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">Success</h3>
                        <div className="mt-2 text-sm text-green-700">
                          <p>Client information has been successfully saved.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

const getPlaceholder = (field) => {
  switch (field) {
    case 'name':
      return 'James Smith';
    case 'email':
      return 'client@example.com';
    case 'phone':
      return '+447888563707';
    case 'address':
      return '10 Downing Street, London, UK';
    default:
      return '';
  }
};

export default ClientInfoSection;
