import React from 'react';
import { Field } from 'formik';

const projectStyles = [
  { id: 'contemporary', title: 'Contemporary' },
  { id: 'classic', title: 'Classic' },
  { id: 'country', title: 'Country' },
  { id: 'notSure', title: 'Not Sure' },
];

const ProjectStyle = () => {
  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Your Project Style</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">Select an option on style</p>

      <div className="mt-10 space-y-10">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900">Please choose a style</legend>
          <div className="mt-6 space-y-6">
            {projectStyles.map((style) => (
              <div key={style.id} className="flex items-center gap-x-3">
                <Field
                  type="radio"
                  id={style.id}
                  name="projectStyle"
                  value={style.id}
                  className="h-4 w-4 border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F]"
                />
                <label htmlFor={style.id} className="block text-sm font-medium leading-6 text-gray-900">
                  {style.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>

        <div className="col-span-full">
          <label htmlFor="styleComments" className="block text-sm font-medium leading-6 text-gray-900">
            Style Comments
          </label>
          <div className="mt-2">
            <Field
              as="textarea"
              id="styleComments"
              name="styleComments"
              rows={3}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectStyle;
