import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon, CheckCircleIcon, XCircleIcon, CalendarIcon, ClockIcon, PencilIcon, DocumentIcon } from '@heroicons/react/24/outline';
import SlideOver from './SlideOver';
import { toast } from 'react-toastify';

const Spinner = () => (
  <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
);

const MilestoneFolder = ({ category, milestones, handleStatusChange, handleDeleteMilestone, handleDeleteSubMilestone, updateMilestone }) => {
  const [visibleSubMilestones, setVisibleSubMilestones] = useState({});
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [updatingSubMilestones, setUpdatingSubMilestones] = useState({});
  const [updatingMainMilestones, setUpdatingMainMilestones] = useState({});

  const handleMilestoneClick = (milestone) => {
    setSelectedMilestone(milestone);
    setIsSlideOverOpen(true);
  };

  const toggleSubMilestonesVisibility = (milestoneId) => {
    setVisibleSubMilestones((prev) => ({
      ...prev,
      [milestoneId]: !prev[milestoneId],
    }));
  };

  const handleMarkMainMilestoneComplete = async (milestoneId) => {
    const milestone = milestones.find((m) => m._id === milestoneId);
    if (!milestone) return;

    const incompleteSubMilestones = milestone.subMilestones.filter((subMilestone) => !subMilestone.status);

    if (incompleteSubMilestones.length > 0) {
      if (window.confirm('Not all sub-milestones are completed. Are you sure you want to mark the main milestone as complete?')) {
        await updateMainMilestoneStatus(milestoneId);
      }
    } else {
      await updateMainMilestoneStatus(milestoneId);
    }
  };

  const updateMainMilestoneStatus = async (milestoneId) => {
    setUpdatingMainMilestones((prev) => ({ ...prev, [milestoneId]: true }));
    try {
      await handleStatusChange(milestoneId);
    } finally {
      setUpdatingMainMilestones((prev) => ({ ...prev, [milestoneId]: false }));
    }
  };

  const confirmSubMilestoneStatusChange = async (mainMilestoneId, subMilestoneId, currentStatus) => {
    const action = currentStatus ? 'incomplete' : 'complete';

    setUpdatingSubMilestones((prev) => ({ ...prev, [subMilestoneId]: true }));
    try {
      await handleStatusChange(mainMilestoneId, subMilestoneId);
    } finally {
      setUpdatingSubMilestones((prev) => ({ ...prev, [subMilestoneId]: false }));
    }
  };

  const handleDeleteMainMilestone = async (milestoneId) => {
    if (window.confirm('This action cannot be undone. Are you sure you want to delete the main milestone?')) {
      try {
        await handleDeleteMilestone(milestoneId);
        toast.success('Milestone deleted successfully!', {
          position: 'bottom-right',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error('Error deleting milestone:', error);
        toast.error('Failed to delete milestone. Please try again.', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const handleDeleteSubMilestoneWithToast = async (milestoneId, subMilestoneId) => {
    try {
      await handleDeleteSubMilestone(milestoneId, subMilestoneId);
      toast.success('Sub-milestone deleted successfully!', {
        position: 'bottom-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error deleting sub-milestone:', error);
      toast.error('Failed to delete sub-milestone. Please try again.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const formatDateFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' });
  };

  const countCompletedSubMilestones = (milestone) => {
    return milestone.subMilestones.filter((subMilestone) => subMilestone.status).length;
  };

  const isDeadlineOverdue = (deadline) => {
    return new Date(deadline) < new Date();
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden h-full">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{category}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {milestones.length} milestone{milestones.length !== 1 ? 's' : ''}
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="space-y-4 sm:px-6 sm:py-5">
          {milestones.map((milestone) => (
            <div key={milestone._id} className="bg-gray-50 rounded-lg p-4 transition duration-300 ease-in-out hover:shadow-md relative">
              <div className="absolute top-4 right-4 flex space-x-2">
                {milestone.documents && milestone.documents.length > 0 && (
                  <div
                    className="flex items-center text-gray-500 cursor-pointer"
                    title={`${milestone.documents.length} document${milestone.documents.length !== 1 ? 's' : ''}`}
                    onClick={() => handleMilestoneClick(milestone)}
                  >
                    <DocumentIcon className="w-5 h-5 mr-1" />
                    <span className="text-xs">{milestone.documents.length}</span>
                  </div>
                )}
                <button
                  onClick={() => handleMilestoneClick(milestone)}
                  className="text-[#2A9D8F] hover:text-[#238277] focus:outline-none"
                  aria-label="Edit milestone"
                >
                  <PencilIcon className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDeleteMainMilestone(milestone._id)}
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  aria-label="Delete milestone"
                >
                  <XCircleIcon className="w-5 h-5" />
                </button>
              </div>

              <h4 className="text-lg font-medium text-gray-900 mb-2 pr-20">{milestone.title}</h4>
              <div className="flex flex-wrap items-center mt-3 space-y-2">
                <div className="flex items-center w-full">
                  <input
                    id={`status-${milestone._id}`}
                    type="checkbox"
                    checked={milestone.status}
                    onChange={() => handleMarkMainMilestoneComplete(milestone._id)}
                    className="w-4 h-4 text-[#2A9D8F] border-gray-300 rounded focus:ring-[#2A9D8F]"
                    disabled={updatingMainMilestones[milestone._id]}
                  />
                  <label htmlFor={`status-${milestone._id}`} className="ml-2 text-sm text-gray-700">
                    {milestone.status ? 'Completed' : 'Incomplete'}
                  </label>
                  {updatingMainMilestones[milestone._id] && (
                    <span className="ml-2">
                      <Spinner />
                    </span>
                  )}
                </div>

                {milestone.status && (
                  <div className="flex items-center text-xs text-gray-500 w-full">
                    <CheckCircleIcon className="w-4 h-4 mr-1 text-[#2A9D8F]" />
                    <span>Completed: {formatDateFromTimestamp(milestone.completedOn)}</span>
                  </div>
                )}

                {milestone.deadline && (
                  <div className="flex items-center text-xs text-gray-500 w-full">
                    <CalendarIcon className="w-4 h-4 mr-1" />
                    <span className={!milestone.status && isDeadlineOverdue(milestone.deadline) ? 'text-red-500 font-semibold' : ''}>
                      Deadline: {formatDateFromTimestamp(milestone.deadline)}
                    </span>
                  </div>
                )}
              </div>

              {milestone.subMilestones && milestone.subMilestones.length > 0 && (
                <div className="mt-4 border-t border-gray-200 pt-4">
                  <button
                    onClick={() => toggleSubMilestonesVisibility(milestone._id)}
                    className="flex items-center justify-between w-full text-sm font-medium text-gray-700 hover:text-[#2A9D8F] focus:outline-none"
                  >
                    <span>
                      Sub-Milestones ({countCompletedSubMilestones(milestone)}/{milestone.subMilestones.length})
                    </span>
                    {visibleSubMilestones[milestone._id] ? <ChevronUpIcon className="w-5 h-5 ml-2" /> : <ChevronDownIcon className="w-5 h-5 ml-2" />}
                  </button>

                  <div
                    className={`mt-2 space-y-2 transition-all duration-300 ease-in-out ${
                      visibleSubMilestones[milestone._id] ? 'max-h-full opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                    }`}
                  >
                    {milestone.subMilestones.map((subMilestone) => (
                      <div key={subMilestone._id} className="bg-white rounded-md p-3 shadow-sm relative">
                        <h5 className="text-sm font-medium text-gray-800 mb-2 pr-8">{subMilestone.title}</h5>
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center">
                            <input
                              id={`status-${milestone._id}-${subMilestone._id}`}
                              type="checkbox"
                              checked={subMilestone.status}
                              onChange={() => confirmSubMilestoneStatusChange(milestone._id, subMilestone._id, subMilestone.status)}
                              className="w-4 h-4 text-[#2A9D8F] border-gray-300 rounded focus:ring-[#2A9D8F]"
                              disabled={updatingSubMilestones[subMilestone._id]}
                            />
                            <label htmlFor={`status-${milestone._id}-${subMilestone._id}`} className="ml-2 text-xs text-gray-600">
                              {subMilestone.status ? 'Completed' : 'Incomplete'}
                            </label>
                            {updatingSubMilestones[subMilestone._id] && (
                              <span className="ml-2">
                                <Spinner />
                              </span>
                            )}
                          </div>
                          {subMilestone.status && (
                            <div className="flex items-center text-xs text-gray-500">
                              <ClockIcon className="w-4 h-4 mr-1" />
                              <span>{formatDateFromTimestamp(subMilestone.completedOn)}</span>
                            </div>
                          )}
                        </div>
                        <button
                          onClick={() => handleDeleteSubMilestoneWithToast(milestone._id, subMilestone._id)}
                          className="absolute top-2 right-2 text-gray-400 hover:text-red-500 focus:outline-none"
                          aria-label="Delete sub-milestone"
                        >
                          <XCircleIcon className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <SlideOver
        open={isSlideOverOpen}
        setOpen={setIsSlideOverOpen}
        milestone={selectedMilestone}
        handleStatusChange={handleStatusChange}
        updateMilestone={updateMilestone}
      />
    </div>
  );
};

const MilestoneList = ({ categories, milestones, handleStatusChange, handleDeleteMilestone, handleDeleteSubMilestone, updateMilestone }) => {
  const uncategorizedMilestones = Array.isArray(milestones) ? milestones.filter((milestone) => !milestone.category || milestone.category === '') : [];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-xl font-semibold text-gray-900">Project Milestones</h1>
      <p className="text-sm text-gray-500 mb-8">
        Milestones are used to track the progress of your project. You can add, edit, and delete milestones, as well as mark them as complete or incomplete.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {categories.map((category) => (
          <MilestoneFolder
            key={category}
            category={category}
            milestones={Array.isArray(milestones) ? milestones.filter((m) => m.category === category) : []}
            handleStatusChange={handleStatusChange}
            handleDeleteMilestone={handleDeleteMilestone}
            handleDeleteSubMilestone={handleDeleteSubMilestone}
            updateMilestone={updateMilestone}
          />
        ))}
      </div>

      {uncategorizedMilestones.length > 0 && (
        <div className="mt-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Uncategorized Milestones</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {uncategorizedMilestones.map((milestone) => (
              <MilestoneFolder
                key={milestone._id}
                category="Uncategorized"
                milestones={[milestone]}
                handleStatusChange={handleStatusChange}
                handleDeleteMilestone={handleDeleteMilestone}
                handleDeleteSubMilestone={handleDeleteSubMilestone}
                updateMilestone={updateMilestone}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MilestoneList;
