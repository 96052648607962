import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const InternalInfo = () => {
  const { errors, touched } = useFormikContext();

  return (
    <div className="border-b border-gray-900/10 pb-12">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Internal Info (MP)</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">Please enter internal information about the project.</p>

      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label htmlFor="jobReference" className="block text-sm font-medium leading-6 text-gray-900">
            Internal Job Reference *
          </label>
          <div className="mt-2">
            <Field
              type="text"
              name="jobReference"
              id="jobReference"
              placeholder="001"
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                errors.jobReference && touched.jobReference ? 'ring-red-500' : 'ring-gray-300'
              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6`}
            />
          </div>
          <ErrorMessage name="jobReference" component="p" className="mt-2 text-sm text-red-600" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="projectManager" className="block text-sm font-medium leading-6 text-gray-900">
            MP Project Manager Email *
          </label>
          <div className="mt-2">
            <Field
              type="text"
              name="projectManager"
              id="projectManager"
              placeholder="manager@project.com"
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                errors.projectManager && touched.projectManager ? 'ring-red-500' : 'ring-gray-300'
              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6`}
            />
          </div>
          <ErrorMessage name="projectManager" component="p" className="mt-2 text-sm text-red-600" />
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="projectStage" className="block text-sm font-medium leading-6 text-gray-900">
            Project Stage *
          </label>
          <div className="mt-2">
            <Field
              as="select"
              name="projectStage"
              id="projectStage"
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                errors.projectStage && touched.projectStage ? 'ring-red-500' : 'ring-gray-300'
              } focus:ring-2 focus:ring-inset focus:ring-[#2A9D8F] sm:text-sm sm:leading-6`}
            >
              <option value="">Select a stage</option>
              <option value="Creation Pack">Creation Pack</option>
              <option value="Home Design Pack">Home Design Pack</option>
              <option value="Construction Pack">Construction Pack</option>
              <option value="Onsite">Onsite</option>
              <option value="On Hold">On Hold</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Complete">Complete</option>
            </Field>
          </div>
          <ErrorMessage name="projectStage" component="p" className="mt-2 text-sm text-red-600" />
        </div>
      </div>
    </div>
  );
};

export default InternalInfo;
