import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, PencilIcon, CheckIcon, DocumentIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { sub } from 'date-fns';
import api from '../../../utils/api';
import { updateMilestone as updateMilestoneService } from '../../../services/milestoneService';
import { toast } from 'react-toastify';
import { addDocumentToMilestone, removeDocumentFromMilestone, getMilestoneWithDocuments } from '../../../services/milestoneService';
import { getDocuments, getSignedUrl } from '../../../services/documentService';
import { useParams } from 'react-router-dom';

const Spinner = () => (
  <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
);

export default function SlideOver({ open, setOpen, milestone, handleStatusChange, updateMilestone }) {
  const { projectId } = useParams(); // Extract projectId from URL params
  const [localMilestone, setLocalMilestone] = useState(milestone || {});
  const [editMode, setEditMode] = useState({
    main: false,
    subMilestones: {},
    deadline: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState({});
  const [milestoneDocuments, setMilestoneDocuments] = useState([]);

  // Adjusted toggleEditMode function
  const toggleEditMode = (id, isSubMilestone = false, isDeadline = false) => {
    setEditMode((prev) => {
      if (isSubMilestone) {
        // Safely toggle the edit mode for subMilestones
        return {
          ...prev,
          subMilestones: {
            ...prev.subMilestones,
            [id]: !prev.subMilestones[id],
          },
        };
      } else if (isDeadline) {
        // Toggle the edit mode for the deadline
        return {
          ...prev,
          deadline: !prev.deadline,
        };
      } else {
        // Toggle the edit mode for the main milestone
        return {
          ...prev,
          main: !prev.main,
        };
      }
    });
  };

  useEffect(() => {
    // Update localMilestone whenever the milestone prop changes
    setLocalMilestone(milestone || {});
  }, [milestone]);

  useEffect(() => {
    if (milestone) {
      fetchMilestoneDocuments();
      fetchAllDocuments();
    }
  }, [milestone]);

  const fetchMilestoneDocuments = async () => {
    try {
      const data = await getMilestoneWithDocuments(milestone._id);
      setMilestoneDocuments(data.documents);
    } catch (error) {
      console.error('Error fetching milestone documents:', error);
      toast.error('Failed to fetch milestone documents');
    }
  };

  const fetchAllDocuments = async () => {
    try {
      // Use the projectId from URL params instead of milestone object
      const data = await getDocuments(projectId);

      setDocuments(data);
    } catch (error) {
      console.error('Error fetching all documents:', error);
      toast.error('Failed to fetch documents');
    }
  };

  const handleAddDocument = async (documentId) => {
    try {
      await addDocumentToMilestone(milestone._id, documentId);
      fetchMilestoneDocuments();
      toast.success('Document added to milestone');
    } catch (error) {
      console.error('Error adding document to milestone:', error);
      toast.error('Failed to add document to milestone');
    }
  };

  const handleRemoveDocument = async (documentId) => {
    try {
      await removeDocumentFromMilestone(milestone._id, documentId);
      fetchMilestoneDocuments();
      toast.success('Document removed from milestone');
    } catch (error) {
      console.error('Error removing document from milestone:', error);
      toast.error('Failed to remove document from milestone');
    }
  };

  // Function to toggle edit mode

  const toggleMainStatus = async () => {
    setIsLoading(true);
    const incompleteSubMilestones = localMilestone.subMilestones?.some((sub) => !sub.status);

    if (incompleteSubMilestones && !window.confirm('Not all sub-milestones are completed. Are you sure you want to mark the main milestone as complete?')) {
      return;
    }

    const newStatus = !localMilestone.status;
    const updatedMilestone = {
      ...localMilestone,
      status: newStatus,
      completedOn: newStatus ? new Date().toISOString() : null,
    };

    try {
      const savedMilestone = await updateMilestoneService(updatedMilestone._id, updatedMilestone);
      setLocalMilestone(savedMilestone);
      updateMilestone(savedMilestone);
      toast.success('Milestone status updated successfully!');
    } catch (error) {
      console.error('Failed to update milestone status:', error);
      toast.error('Failed to update milestone status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSubMilestoneStatus = async (subMilestoneId) => {
    setIsLoading(true);
    const updatedSubMilestones = localMilestone.subMilestones.map((subMilestone) => {
      if (subMilestone._id === subMilestoneId) {
        const newStatus = !subMilestone.status;
        return {
          ...subMilestone,
          status: newStatus,
          completedOn: newStatus ? new Date().toISOString() : null,
        };
      }
      return subMilestone;
    });

    const updatedMilestone = {
      ...localMilestone,
      subMilestones: updatedSubMilestones,
    };

    try {
      const savedMilestone = await updateMilestoneService(updatedMilestone._id, updatedMilestone);
      setLocalMilestone(savedMilestone);
      updateMilestone(savedMilestone);
      toast.success('Sub-milestone status updated successfully!');
    } catch (error) {
      console.error('Failed to update sub-milestone status:', error);
      toast.error('Failed to update sub-milestone status. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTitleChange = (event, id, isSubMilestone = false) => {
    if (isSubMilestone) {
      const updatedSubMilestones = localMilestone.subMilestones.map((sub) => {
        if (sub._id === id) {
          return { ...sub, title: event.target.value };
        }
        return sub;
      });
      setLocalMilestone((prev) => ({
        ...prev,
        subMilestones: updatedSubMilestones,
      }));
    } else {
      setLocalMilestone((prev) => ({
        ...prev,
        title: event.target.value,
      }));
    }
  };

  const handleDeadlineChange = (e) => {
    setLocalMilestone({ ...localMilestone, deadline: e.target.value });
  };

  const saveChanges = async () => {
    setIsLoading(true);
    try {
      // Update the milestone using the service
      const updatedMilestone = await updateMilestoneService(localMilestone._id, localMilestone);

      // Update the local state
      setLocalMilestone(updatedMilestone);

      // Call the updateMilestone prop to update the parent component's state
      updateMilestone(updatedMilestone);

      // Exit edit mode
      setEditMode({ main: false, subMilestones: {}, deadline: false });

      // Show a success message
      toast.success('Milestone updated successfully!');
    } catch (error) {
      console.error('Update failed', error);
      toast.error('Failed to update milestone. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Not completed';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Modify the flattenDocuments function to maintain category structure
  const organizeDocuments = () => {
    return Object.entries(documents).reduce((acc, [category, subCategories]) => {
      acc[category] = Object.values(subCategories).flat();
      return acc;
    }, {});
  };

  const handleDocumentClick = async (doc) => {
    try {
      let url;
      if (doc.key) {
        // If the document has a key, fetch the signed URL
        url = await getSignedUrl(doc.key);
      } else if (doc.url) {
        // If the document has a direct URL, use it
        url = doc.url;
      } else {
        throw new Error('No valid URL or key available for this document.');
      }

      // Open the URL in a new tab
      window.open(url, '_blank');

      // Show a success message
      toast.success('Opening document in a new tab.');
    } catch (error) {
      console.error('Error opening document:', error);
      toast.error(error.message || 'Failed to open document.');
    }
  };

  if (!milestone) {
    return null; // Early return if milestone is null, rendering nothing
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                  {' '}
                  {/* Changed from max-w-md to max-w-2xl */}
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-[#2A9D8F] px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">Milestone Details</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-[#2A9D8F] text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 py-6 sm:px-6">
                      {isLoading ? (
                        <div className="flex justify-center items-center h-full">
                          <Spinner />
                        </div>
                      ) : localMilestone ? (
                        <div className="space-y-6">
                          {/* Title */}
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Title</h3>
                              {editMode.main ? (
                                <div className="mt-1 flex items-center">
                                  <input
                                    type="text"
                                    value={localMilestone.title}
                                    onChange={(event) => handleTitleChange(event, localMilestone._id, false)}
                                    className="shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full sm:text-sm border-gray-300 rounded-md"
                                  />
                                  <button
                                    onClick={saveChanges}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                  >
                                    <CheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                    Save
                                  </button>
                                </div>
                              ) : (
                                <div className="mt-1 flex justify-between items-center">
                                  <p className="text-sm text-gray-900">{localMilestone.title}</p>
                                  <button onClick={() => toggleEditMode(localMilestone._id, false)} className="text-[#2A9D8F] hover:text-[#238276]">
                                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Category */}
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Category</h3>
                              <p className="mt-1 text-sm text-gray-600">{localMilestone.category}</p>
                            </div>
                          </div>

                          {/* Deadline */}
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Deadline</h3>
                              {editMode.deadline ? (
                                <div className="mt-1 flex items-center">
                                  <input
                                    type="date"
                                    className="shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full sm:text-sm border-gray-300 rounded-md"
                                    value={localMilestone.deadline ? new Date(localMilestone.deadline).toISOString().substring(0, 10) : ''}
                                    onChange={handleDeadlineChange}
                                  />
                                  <button
                                    onClick={saveChanges}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                  >
                                    <CheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                    Save
                                  </button>
                                </div>
                              ) : (
                                <div className="mt-1 flex justify-between items-center">
                                  <p className="text-sm text-gray-600">
                                    {localMilestone.deadline ? new Date(localMilestone.deadline).toLocaleDateString() : 'No Deadline Set'}
                                  </p>
                                  <button onClick={() => toggleEditMode(localMilestone._id, false, true)} className="text-[#2A9D8F] hover:text-[#238276]">
                                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Status */}
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Status</h3>
                              <div className="mt-2 flex items-center">
                                <input
                                  id={`status-${localMilestone._id}`}
                                  name={`status-${localMilestone._id}`}
                                  type="checkbox"
                                  checked={localMilestone.status}
                                  onChange={toggleMainStatus}
                                  className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                                />
                                <label htmlFor={`status-${localMilestone._id}`} className="ml-2 block text-sm text-gray-900">
                                  {localMilestone.status ? `Completed on ${formatDate(localMilestone.completedOn)}` : 'Incomplete'}
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Documents Section */}
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Documents</h3>
                              {milestoneDocuments.length > 0 ? (
                                <ul className="mt-3 divide-y divide-gray-200">
                                  {milestoneDocuments.map((doc) => (
                                    <li key={doc._id} className="py-3 flex justify-between items-center">
                                      <div className="flex items-center cursor-pointer" onClick={() => handleDocumentClick(doc)}>
                                        <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                                        <span className="text-sm font-medium text-gray-900">{doc.name}</span>
                                      </div>
                                      <button onClick={() => handleRemoveDocument(doc._id)} className="text-sm text-red-600 hover:text-red-900">
                                        Remove
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p className="mt-2 text-sm text-gray-500">No documents attached to this milestone.</p>
                              )}
                            </div>
                          </div>

                          {/* Add Document Section */}
                          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Add Document</h3>
                              <select
                                className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                onChange={(e) => handleAddDocument(e.target.value)}
                                value=""
                              >
                                <option value="" disabled>
                                  Select a document to add
                                </option>
                                {Object.entries(organizeDocuments()).map(([category, docs]) => (
                                  <optgroup label={category} key={category}>
                                    {docs
                                      .filter((doc) => !milestoneDocuments.some((mDoc) => mDoc._id === doc._id))
                                      .map((doc) => (
                                        <option key={doc._id} value={doc._id}>
                                          {doc.name} {doc.subCategory ? `(${doc.subCategory})` : ''}
                                        </option>
                                      ))}
                                  </optgroup>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* SubMilestones */}
                          {localMilestone.subMilestones?.length > 0 && (
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                              <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Sub-Milestones</h3>
                                <ul className="mt-3 space-y-3">
                                  {localMilestone.subMilestones.map((subMilestone) => (
                                    <li key={subMilestone._id} className="bg-gray-50 px-4 py-3 sm:rounded-md">
                                      {editMode.subMilestones[subMilestone._id] ? (
                                        <div className="flex items-center">
                                          <input
                                            type="text"
                                            value={subMilestone.title}
                                            onChange={(event) => handleTitleChange(event, subMilestone._id, true)}
                                            className="shadow-sm focus:ring-[#2A9D8F] focus:border-[#2A9D8F] block w-full sm:text-sm border-gray-300 rounded-md"
                                          />
                                          <button
                                            onClick={saveChanges}
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#238276] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]"
                                          >
                                            <CheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                            Save
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="flex justify-between items-center">
                                          <div className="flex items-center">
                                            <input
                                              type="checkbox"
                                              checked={subMilestone.status}
                                              onChange={() => toggleSubMilestoneStatus(subMilestone._id)}
                                              className="h-4 w-4 text-[#2A9D8F] focus:ring-[#2A9D8F] border-gray-300 rounded"
                                            />
                                            <span className="ml-3 text-sm text-gray-900">{subMilestone.title}</span>
                                          </div>
                                          <button onClick={() => toggleEditMode(subMilestone._id, true)} className="text-[#2A9D8F] hover:text-[#238276]">
                                            <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                          </button>
                                        </div>
                                      )}
                                      {subMilestone.status && <p className="mt-1 text-xs text-gray-500">Completed on {formatDate(subMilestone.completedOn)}</p>}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>No milestone data available.</p>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
