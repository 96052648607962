import React, { useState, useEffect } from 'react';
import { milestoneService } from '../../services/milestoneService';
import { toast } from 'react-toastify';

export default function MilestoneTemplates({ organisation }) {
  const [templates, setTemplates] = useState([]);
  const [newTemplate, setNewTemplate] = useState({
    title: '',
    category: '',
    description: '',
    subMilestones: [],
  });
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [newSubMilestone, setNewSubMilestone] = useState('');

  const categories = ['Design Milestones', 'Permissions Milestones', 'Support Milestones', 'Onsite Milestones'];

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const fetchedTemplates = await milestoneService.getMilestoneTemplates(organisation._id);
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error fetching milestone templates:', error);
      toast.error('Failed to fetch milestone templates');
    }
  };

  const handleCreateTemplate = async () => {
    try {
      const createdTemplate = await milestoneService.createMilestoneTemplate(organisation._id, newTemplate);
      setTemplates([...templates, createdTemplate]);
      setNewTemplate({ title: '', category: '', description: '', subMilestones: [] });
      toast.success('Milestone template created successfully');
    } catch (error) {
      console.error('Error creating milestone template:', error);
      toast.error('Failed to create milestone template');
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      const updatedTemplate = await milestoneService.updateMilestoneTemplate(organisation._id, editingTemplate._id, editingTemplate);
      setTemplates(templates.map((t) => (t._id === updatedTemplate._id ? updatedTemplate : t)));
      setEditingTemplate(null);
      toast.success('Milestone template updated successfully');
    } catch (error) {
      console.error('Error updating milestone template:', error);
      toast.error('Failed to update milestone template');
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const confirmMessage =
        'Are you sure you want to delete this milestone template?\n\nThis action cannot be undone, and the template will be permanently removed.';

      if (window.confirm(confirmMessage)) {
        await milestoneService.deleteMilestoneTemplate(organisation._id, templateId);
        setTemplates(templates.filter((t) => t._id !== templateId));
        toast.success('Milestone template deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting milestone template:', error);
      toast.error('Failed to delete milestone template');
    }
  };

  const handleAddSubMilestone = () => {
    if (newSubMilestone.trim() !== '') {
      setNewTemplate({
        ...newTemplate,
        subMilestones: [...newTemplate.subMilestones, { title: newSubMilestone.trim() }],
      });
      setNewSubMilestone('');
    }
  };

  const handleRemoveSubMilestone = (index) => {
    setNewTemplate({
      ...newTemplate,
      subMilestones: newTemplate.subMilestones.filter((_, i) => i !== index),
    });
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Milestone Templates</h2>

      {/* Create new template form */}
      <div className="bg-white shadow sm:rounded-lg p-6">
        <h3 className="text-lg font-medium mb-4">Create New Template</h3>
        <div className="space-y-4">
          <div>
            <label htmlFor="templateTitle" className="block text-sm font-medium text-gray-700 mb-1">
              Template Title
            </label>
            <input
              id="templateTitle"
              type="text"
              placeholder="Enter template title"
              value={newTemplate.title}
              onChange={(e) => setNewTemplate({ ...newTemplate, title: e.target.value })}
              className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="templateCategory" className="block text-sm font-medium text-gray-700 mb-1">
              Category
            </label>
            <select
              id="templateCategory"
              value={newTemplate.category}
              onChange={(e) => setNewTemplate({ ...newTemplate, category: e.target.value })}
              className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="templateDescription" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="templateDescription"
              placeholder="Enter template description"
              value={newTemplate.description}
              onChange={(e) => setNewTemplate({ ...newTemplate, description: e.target.value })}
              className="w-full p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
              rows="3"
            />
          </div>
          <div>
            <h4 className="font-medium mb-2">Sub-milestones</h4>
            <p className="text-sm text-gray-600 mb-2">Add sub-milestones to break down your template into smaller, manageable tasks.</p>
            {newTemplate.subMilestones.map((subMilestone, index) => (
              <div key={index} className="flex items-center mb-2">
                <span className="flex-grow">{subMilestone.title}</span>
                <button onClick={() => handleRemoveSubMilestone(index)} className="text-red-500 ml-2 hover:text-red-700 transition duration-300">
                  Remove
                </button>
              </div>
            ))}
            <div className="flex">
              <input
                type="text"
                placeholder="Enter new sub-milestone"
                value={newSubMilestone}
                onChange={(e) => setNewSubMilestone(e.target.value)}
                className="flex-grow p-2 border rounded-l-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
              />
              <button
                onClick={handleAddSubMilestone}
                className="bg-[#2A9D8F] text-white px-4 py-2 rounded-r-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
              >
                Add
              </button>
            </div>
            <p className="text-sm text-gray-600 mt-1">Type a sub-milestone and click 'Add' to include it in your template.</p>
          </div>
        </div>
        <button
          onClick={handleCreateTemplate}
          className="mt-4 w-full inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#2A9D8F] hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
        >
          Create Template
        </button>
      </div>

      {/* List of existing templates */}
      <div className="bg-white shadow sm:rounded-lg p-4">
        <h3 className="text-lg font-medium mb-4">Existing Templates</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {templates.map((template) => (
            <div key={template._id} className="border rounded-lg p-4">
              <h4 className="font-semibold mb-2">{template.title}</h4>
              <p className="text-sm text-gray-600 mb-2">{template.category}</p>
              <p className="text-sm mb-4 text-gray-700">{template.description}</p>
              <div className="mt-2">
                <h5 className="font-medium text-sm mb-1">Sub-milestones:</h5>
                <ul className="list-disc list-inside">
                  {template.subMilestones.map((subMilestone, index) => (
                    <li key={index} className="text-sm">
                      {subMilestone.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-4 flex justify-end">
                <button onClick={() => setEditingTemplate(template)} className="text-[#2A9D8F] hover:text-[#264653] transition duration-300 mr-2">
                  Edit
                </button>
                <button onClick={() => handleDeleteTemplate(template._id)} className="text-red-500 hover:text-red-700 transition duration-300">
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Edit template modal */}
      {editingTemplate && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-start justify-center z-50 pt-10">
          <div className="bg-white p-4 rounded-lg max-w-lg w-full">
            <h3 className="text-lg font-medium mb-4">Edit Template</h3>
            <input
              type="text"
              value={editingTemplate.title}
              onChange={(e) => setEditingTemplate({ ...editingTemplate, title: e.target.value })}
              className="w-full mb-2 p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            />
            <select
              value={editingTemplate.category}
              onChange={(e) => setEditingTemplate({ ...editingTemplate, category: e.target.value })}
              className="w-full mb-2 p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <textarea
              value={editingTemplate.description}
              onChange={(e) => setEditingTemplate({ ...editingTemplate, description: e.target.value })}
              className="w-full mb-2 p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
              rows="3"
            />
            <div className="mb-2">
              <h4 className="font-medium mb-1">Sub-milestones</h4>
              {editingTemplate.subMilestones.map((subMilestone, index) => (
                <div key={index} className="flex items-center mb-1">
                  <input
                    type="text"
                    value={subMilestone.title}
                    onChange={(e) => {
                      const updatedSubMilestones = [...editingTemplate.subMilestones];
                      updatedSubMilestones[index].title = e.target.value;
                      setEditingTemplate({ ...editingTemplate, subMilestones: updatedSubMilestones });
                    }}
                    className="flex-grow p-2 border rounded-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
                  />
                  <button
                    onClick={() => {
                      const updatedSubMilestones = editingTemplate.subMilestones.filter((_, i) => i !== index);
                      setEditingTemplate({ ...editingTemplate, subMilestones: updatedSubMilestones });
                    }}
                    className="text-red-500 ml-2 hover:text-red-700 transition duration-300"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <div className="flex">
                <input
                  type="text"
                  placeholder="New Sub-milestone"
                  value={newSubMilestone}
                  onChange={(e) => setNewSubMilestone(e.target.value)}
                  className="flex-grow p-2 border rounded-l-md border-gray-300 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm"
                />
                <button
                  onClick={() => {
                    if (newSubMilestone.trim() !== '') {
                      setEditingTemplate({
                        ...editingTemplate,
                        subMilestones: [...editingTemplate.subMilestones, { title: newSubMilestone.trim() }],
                      });
                      setNewSubMilestone('');
                    }
                  }}
                  className="bg-[#2A9D8F] text-white px-4 py-2 rounded-r-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
                >
                  Add
                </button>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button onClick={() => setEditingTemplate(null)} className="text-gray-500 mr-2 hover:text-gray-700 transition duration-300">
                Cancel
              </button>
              <button
                onClick={handleUpdateTemplate}
                className="bg-[#2A9D8F] text-white px-4 py-2 rounded-md hover:bg-[#264653] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] transition duration-300"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
